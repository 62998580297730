import React, { useState, useContext, useEffect } from 'react';
//Libraries
import { AutoComplete, Row, Col, Divider, InputNumber } from 'antd';
//Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
//Components
import Text from '~/components/typography/text';
import Button from '~/components/button';
//Utils
import { WalletFormatParser } from '~/utils/Parsers';

export default function AddForm({ CloseDrawer, GetProductsAgain }) {
  const { CallToTheAPI } = useContext(AxiosContext);

  const [inputValue, setInputValue] = useState();
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [originalList, setOriginalList] = useState([]);
  const [offering, setOffering] = useState();
  const [offerUnderCreation, setOfferUnderCreation] = useState({
    measurementUnity: '',
    value: '',
  });

  useEffect(() => {
    async function GetData(data) {
      const params = {
        operation: ['Partner', 'Product', 'Search'],
        data: {
          filter: {
            kind: 'SERVICE',
            searchString: inputValue,
          },
          limit: 10,
          pagination: {
            sort: {
              by: 'name',
              order: 'desc',
            },
          },
        },
      };
      const response = await CallToTheAPI(params);
      //console.log('Response -> ', response);
      if (response.success) {
        async function Treatment() {
          setAutoCompleteOptions([]);
          const treatment = [];
          await response.body.psModels.elements.map((item) => {
            const newItem = {
              value: item.name,
            };
            return treatment.push(newItem);
          });
          setOriginalList(response.body.psModels.elements);
          setAutoCompleteOptions(treatment);
        }
        Treatment();
      }
    }
    if (inputValue) {
      if (inputValue.length > 0) {
        //console.log('UseEffect(InputValue) -> ', inputValue);
        GetData();
      }
    }
  }, [CallToTheAPI, inputValue]);

  const onSelectAutoComplete = (data) => {
    setAutoCompleteOptions([]);
    //console.log('Select -> ' + data);
    async function Treatment() {
      originalList.map((item) => {
        if (item.name === data) {
          let newItem = item;
          return setOffering(newItem);
        }
        return null;
      });
    }
    Treatment();
  };

  const onChangeAutoComplete = (data) => {
    setAutoCompleteOptions([]);
    setInputValue(data);
  };

  async function AddOffering() {
    const params = {
      operation: ['Partner', 'Product', 'Add'],
      data: {
        kind: 'SERVICE',
        name: offering.name,
        category: offering.category,
        description: offering.description,
        avatar: offering.avatar,
        status: 'ACTIVE',
        tags: offering.tags,
        akas: offering.aka,
        value: WalletFormatParser(offerUnderCreation.value),
        measurementUnity: 60,
        product_service_model: offering.id,
      },
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      setInputValue('');
      setAutoCompleteOptions([]);
      setOriginalList([]);
      setOffering();
      setOfferUnderCreation({
        measurementUnity: '',
        value: '',
      });
      GetProductsAgain();
      return CloseDrawer();
    }
  }

  return (
    <div>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Text strong>
            Por favor, insira o nome do serviço que deseja no campo abaixo
          </Text>
          <AutoComplete
            size={'default'}
            style={{ width: '100%' }}
            value={inputValue}
            options={autoCompleteOptions}
            onSelect={onSelectAutoComplete}
            onChange={onChangeAutoComplete}
            placeholder="Digite abaixo o nome do serviço"
          />
        </Col>
      </Row>
      <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Text strong>Insira o valor do serviço</Text>
            <InputNumber
              decimalSeparator={','}
              precision={2}
              step={1}
              size="default"
              onChange={(value) =>
                setOfferUnderCreation({
                  ...offerUnderCreation,
                  value: value,
                })
              }
            />
          </Col>
        </Row>
      <Divider />
      <Button
        type="primary"
        disabled={offerUnderCreation.value ? false : true}
        onClick={() => AddOffering()}
      >
        Criar serviço
      </Button>
    </div>
  );
}
