import React from 'react';
import ReactToPrint from 'react-to-print';
import Text from '~/components/typography/text';
import Button from '~/components/button';
import QRCode from 'qrcode.react';

class ComponentToPrint extends React.Component {
  render() {
    return (
      <div
        style={{
          width: 840,
          height: 380,
          //height: 1185,
          backgroundImage:
            "url('https://socialme-assets.s3.us-east-1.amazonaws.com/images/qrCode/QRCodeRegua1.png')",
          backgroundPosition: 'center top',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '840px',
          padding: '0px 0px 0px 600px',
        }}
      >
        <div
          style={{
            //backgroundColor: 'black',
            width: 210,
            height: 210,
            borderRadius: '0px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'column',
            paddingTop: 5,
          }}
        >
          <QRCode
            value={this.props.qrCode}
            size={150}
            bgColor="#ffffff"
            fgColor="#303030"
            level="L"
            includeMargin={false}
            renderAs="svg"
          />
          <div style={{ backgroundColor: '#fff' }}>
            <Text strong margin="10px 0px 0px 0px" textAlign="center">
              {this.props.name}
            </Text>
          </div>
        </div>
      </div>
    );
  }
}

class Example extends React.Component {
  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => (
            <Button type="primary" style={{ marginBottom: 10 }}>
              Confirmar impressão
            </Button>
          )}
          content={() => this.componentRef}
        />
        <ComponentToPrint
          qrCode={this.props.qrCode}
          name={this.props.name}
          ref={(el) => (this.componentRef = el)}
        />
      </div>
    );
  }
}

export default Example;
