import styled from 'styled-components';

import { Tag } from 'antd';

export const Div = styled.div`
  max-width: ${(props) => props.maxWidth};
`;

export const PopupDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

  & span {
    margin-bottom: 5px;
  }

  & span:last-child {
    margin-bottom: 0;
  }
`;

export const MainTag = styled(Tag)``;
