import React, { useContext, useEffect, useState } from 'react';
// Libraries
import { useParams, useHistory } from 'react-router-dom';
import { Divider } from 'antd';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
// Own Components
import Header from './components/Header';
import Content from './components/Content';
// Components
import Card from '~/components/card';
import Loading from '~/components/loading';

export default function Activity() {
  const { CallToTheAPI } = useContext(AxiosContext);
  const history = useHistory();
  const { activityId } = useParams();

  const [loadingContent, setLoadingContent] = useState(true);
  const [activity, setActivity] = useState();

  useEffect(() => {
    async function Have(array) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].id === parseInt(activityId)) {
          setActivity(array[i]);
          return true;
        }
      }
      return false;
    }
    async function GetActivities() {
      const params = {
        operation: ['Partner', 'Activity', 'List'],
        data: {
          pagination: {
            limit: 999,
          },
        },
      };
      const response = await CallToTheAPI(params);
      if (response.success) {
        const have = await Have(response.body.activities.elements);
        if (have) {
          return setLoadingContent(false);
        }
        history.push(`/atividades`);
      }
    }
    GetActivities();
  }, [CallToTheAPI, activityId, history]);

  async function Have(array) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === parseInt(activityId)) {
        setActivity(array[i]);
        return true;
      }
    }
    return false;
  }
  async function GetActivitiesAgain() {
    const params = {
      operation: ['Partner', 'Activity', 'List'],
      data: {
        pagination: {
          limit: 999,
        },
      },
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      const have = await Have(response.body.activities.elements);
      if (have) {
        return setLoadingContent(false);
      }
      history.push(`/atividades`);
    }
  }

  return (
    <div>
      {loadingContent ? (
        <Loading text="Verificação dados" />
      ) : (
        <div>
          <Card>
            <Header data={activity} GetDataAgain={GetActivitiesAgain} />
            <Divider />
            <Content />
          </Card>
        </div>
      )}
    </div>
  );
}
