import React, { useEffect, useState, useContext } from 'react';
// Libraries
import { Row, Col, Mentions } from 'antd';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
//Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import TextArea from '~/components/textArea';
import Select from '~/components/select';
import Checkbox from '~/components/checkbox';
import DynamicTags from '~/components/tags/dynamicTags';
import Text from '~/components/typography/text';
// Own Components
import UploadAvatar from '../UploadAvatar';

const { Option } = Mentions

export default function AddForm({ formValues, tags, setTags, akas, setAkas }) {
  const { CallToTheAPI } = useContext(AxiosContext);

  const [activityTypes, setActivityTypes] = useState([]);

  useEffect(() => {
    async function GetActivityTypes() {
      const params = {
        operation: ['System', 'ActivityType', 'ListSimple'],
      };
      const response = await CallToTheAPI(params);
      console.log(response)
      if (response.success) {
        let array = response.body.activityTypes.elements;
        const treatment = [];
        await array.map((item) => {
          let newItem = {};
          newItem.value = item.id;
          newItem.label = item.name;
          return treatment.push(newItem);
        });
        await setActivityTypes(treatment);
      }
    }
    GetActivityTypes();
  }, [CallToTheAPI]);

  const VisibilityOptions = [
    {
      value: 'PUBLIC',
      label: 'Público',
    },
    {
      value: 'PRIVATE',
      label: 'Privado',
    },
  ];

  const registeredOptions = [
    {
      value: 'OWNER',
      label: 'Somente pelo parceiro',
    },
    {
      value: 'USER',
      label: 'Somente pelo beneficiário',
    },
    {
      value: 'BOTH',
      label: 'Ambos',
    },
  ];

  return (
    <div>
      <FormItem
        label="Nome"
        name={['name']}
        rules={[
          {
            required: true,
            message: 'Um nome é obrigatório',
          },
        ]}
        item={<Input />}
      />
      {activityTypes.length > 0 ? (
        <FormItem
          label="Tipo de Atividade"
          name={['activity_type']}
          rules={[
            {
              required: true,
              message: 'Um tipo é obrigatório',
            },
          ]}
          item={<Select options={activityTypes} noDefaultValue />}
        />
      ) : (
          <FormItem
            label="Tipo de Atividade"
            rules={[
              {
                required: true,
                message: 'Um tipo é obrigatório',
              },
            ]}
            item={<Select options={[]} disabled={true} loading={true} />}
          />
        )}
      <FormItem
        label="Descrição Curta"
        name={['short_description']}
        item={<TextArea autoSize={{ minRows: 2, maxRows: 2 }} />}
      />
      <FormItem
        label="Descrição Completa"
        name={['description']}
        item={<TextArea autoSize={{ minRows: 3, maxRows: 6 }} />}
      />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <FormItem
          label="Tipo"
          name={['visibility']}
          item={<Select options={VisibilityOptions} />}
        />
        <FormItem
          label="Status"
          name="status"
          valuePropName="checked"
          item={<Checkbox text={'Ativo'} />}
        />
        <FormItem
          label="Blockchain"
          name="saves_in_block_chain"
          valuePropName="checked"
          item={<Checkbox text={'Salvar em Blockchain'} />}
        />
      </div>

      <FormItem
        label="Documento comprovante"
        name="proof_of_attendance"
        valuePropName="checked"
        item={<Checkbox text={'Emitir documento comprovante'} />}
      />


      {formValues.proof_of_attendance ?
        <>
          <FormItem
            label="Documento Comprovante"
            name={['proof_of_attendance_document_model']}
            rules={[
              {
                required: true,
                message: 'Um modelo é obrigatório',
              },
            ]}
            item={
              <Mentions rows="6" placeholder="Você pode usar o símbolo @ para inserir ariáveis dinâmicas">
                <Option value="NOME_PARTICIPANTE">NOME_PARTICIPANTE</Option>
                <Option value="CPF_PARTICIPANTE">CPF_PARTICIPANTE</Option>
                <Option value="NOME_ATIVIDADE">NOME_ATIVIDADE</Option>
                <Option value="DESCRICAO_ATIVIDADE">DESCRICAO_ATIVIDADE</Option>
                <Option value="DESCRICAO_CURTA_ATIVIDADE">DESCRICAO_CURTA_ATIVIDADE</Option>
                <Option value="NOME_TIPO_ATIVIDADE">NOME_TIPO_ATIVIDADE</Option>
                <Option value="DESCRICAO_TIPO_ATIVIDADE">DESCRICAO_TIPO_ATIVIDADE</Option>
                <Option value="NOME_LUGAR">NOME_LUGAR</Option>
                <Option value="DESCRICAO_LUGAR">DESCRICAO_LUGAR</Option>
                <Option value="DESCRICAO_CURTA_LUGAR">DESCRICAO_CURTA_LUGAR</Option>
                <Option value="FREQUENCIA_ID">FREQUENCIA_ID</Option>
                <Option value="FREQUENCIA_DATA">FREQUENCIA_DATA</Option>
                <Option value="FREQUENCIA_HORA">FREQUENCIA_HORA</Option>
                <Option value="FREQUENCIA_DATHORA">FREQUENCIA_DATHORA</Option>
              </Mentions>
            }
          />
          <Text>Digite o símbolo @ para inserir variáveis dinâmicas</Text>
        </>
        :
        <></>
      }

      <FormItem
        label="Registro de frequência"
        name={['can_be_registered_by']}
        item={<Select options={registeredOptions} />}
      />

      <FormItem
        label="Campos complementares"
        name="has_custom_fields"
        valuePropName="checked"
        item={<Checkbox text={'Criar campos complementares posteriomente'} />}
      />

      <Row>
        <Col span={12}>
          <FormItem label="Avatar" name={['avatar']} item={<UploadAvatar />} />
        </Col>
        <Col span={12}>
          <div style={{ display: 'flex', justifyContent: 'flex-sartt', flexDirection: 'column' }}>
            <FormItem
              label="Tags"
              name={['tags']}
              item={<DynamicTags tags={tags} setTags={setTags} />}
            />
            <FormItem
              label="AKAS"
              name={['akas']}
              item={<DynamicTags tags={akas} setTags={setAkas} />}
            />
          </div>
        </Col>
      </Row>
    </div >
  );
}
