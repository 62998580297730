import React, { memo, useContext } from 'react';

import Text from '~/components/typography/text';

import { ThemeContext } from '~/contexts/ThemeContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';

import Box from './style';

function PrimaryTextCell({ text, color, position, strong }) {
  const { theme } = useContext(ThemeContext);
  const { isMobile } = useContext(ResponsiveContext);

  return (
    <Box position={position || 'flex-start'}>
      <Text
        style={{ textAlign: isMobile && 'center' }}
        strong={strong || false}
        color={color || theme.color.textOnSecondaryBackground}
      >
        {text}
      </Text>
    </Box>
  );
}

export default memo(PrimaryTextCell);
