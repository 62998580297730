import React from 'react';
//Own Components
//import Actions from './components/Actions';
//Components
import { ImageWithTextCell, DateAndTimeCell } from '~/components/table/cells';
import Table from '~/components/table';

export default function Content({ data }) {
  console.log('data -> ', data)
  const tableColumns = [
    {
      title: 'Pessoa',
      dataIndex: 'person_name',
      key: 'person_name',
      render: (person_name, record) => (
        <ImageWithTextCell text={person_name} src={record.person_avatar} />
      ),
    },
    {
      title: 'Data/Hora',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => <DateAndTimeCell timestamp={created_at} />,
    }
  ];

  return (
    <div>
      <Table tableColumns={tableColumns} dataReceived={data} />
    </div>
  );
}
