import styled from 'styled-components';

export const Container = styled.div`
`;

export const Section = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const SectionGroup = styled.div`
  margin: 5px;
`;