import React from 'react';
//Own Components
//import Actions from './components/Actions';
//Components
import {
  ImageWithPrimaryAndSecondaryTextCell,
  DateAndTimeCell,
} from '~/components/table/cells';
import Table from '~/components/table';

export default function Content({ data, GetDataAgain, pageParameters }) {
  const tableColumns = [
    {
      title: 'Pessoa',
      dataIndex: 'person_name',
      key: 'person_name',
      render: (person_name, record) => (
        <ImageWithPrimaryAndSecondaryTextCell
          image={record.person_avatar}
          primaryText={person_name}
          secondaryText={`Identificação: ${record._id}`}
        />
      ),
    },
    {
      title: 'Data/Hora',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => <DateAndTimeCell timestamp={created_at} />,
    },
    {
      title: 'Local',
      dataIndex: 'place_name',
      key: 'place_name',
    },
  ];

  return (
    <div>
      <Table tableColumns={tableColumns} dataReceived={data} />
    </div>
  );
}
