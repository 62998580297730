import React from 'react';
//Libraries
import { Space } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
// Own Components
import EditButton from '../../../../../../components/EditButton';
import DeleteButton from '../../../../../../components/DeleteButton';
import PublishButton from '../../../../../../components/PublishButton';
//Components
import Button from '~/components/button';

export default function Actions({ data, GetDataAgain }) {
  const { path } = useRouteMatch();
  console.log(data)
  return (
    <span
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      <Space>
        {data.is_complete ?
          <Link to={`${path}/${data.id}/registrar-frequencia`}>
            <Button type="primary">Registrar Frequência</Button>
          </Link>
          :
          <PublishButton itemData={data} GetDataAgain={GetDataAgain} />
        }

        <Link to={`${path}/${data.id}`}>
          <Button>Detalhes</Button>
        </Link>
        <EditButton itemData={data} GetDataAgain={GetDataAgain} />
        <DeleteButton itemData={data} GetDataAgain={GetDataAgain} />
      </Space>
    </span>
  );
}
