import React from 'react';
// Components
import Tabs from '~/components/tabs';
// Tabs
import MyActivities from './tabs/MyActivities';
import Frequencies from './tabs/Frequencies';

export default function Activities() {
  const tabsItems = [
    {
      tabPath: '/minhas-atividades',
      title: 'Minhas Atividades',
      badgeCount: 0,
      component: MyActivities,
    },
    {
      tabPath: '/frequencias',
      title: 'Frequências',
      badgeCount: 0,
      component: Frequencies,
    },
  ];
  const tabRedirect = {
    to: '/minhas-atividades',
  };

  return (
    <div>
      <Tabs tabsItems={tabsItems} tabRedirect={tabRedirect} />
    </div>
  );
}
