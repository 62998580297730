import React, { useContext, memo } from 'react';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import { ThemeContext } from '~/contexts/ThemeContext';

import { MainInputNumber } from './style';

function AntInputNumber({
  min,
  max,
  value,
  defaultValue,
  style,
  onChange,
  size,
  step,
  formatter,
  onBlur,
}) {
  const { theme } = useContext(ThemeContext);
  const { isMobile } = useContext(ResponsiveContext);

  return (
    <MainInputNumber
      size={size || isMobile ? 'large' : 'default'}
      formatter={formatter}
      onBlur={onBlur}
      min={min}
      max={max}
      step={step}
      style={style}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      backgroundcolor={theme.color.white}
    />
  );
}

export default memo(AntInputNumber);