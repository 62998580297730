import React from 'react';
// Libraries
import { Result } from 'antd';

export default function Publish() {
    return (
        <div>
            <Result
                status="success"
                title="Atividade criada com sucesso!"
                subTitle="Clique me 'Publicar' para tornar sua atividade operante no sistema"
            />
        </div>
    )
}