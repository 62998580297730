import React, { useContext } from 'react';
// Libraries
import { Link } from 'react-router-dom';
import { Row, Col, Space } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Standalone components
import { Container } from './style';
// Own Components
import EditButton from '../../../../components/EditButton';
import DeleteButton from '../../../../components/DeleteButton';
//Components
import Title from '~/components/typography/title';
import Button from '~/components/button';
//Utils
import { CapitalizeString } from '~/utils/Transformations';

export default function Header({ data, GetDataAgain }) {
  const { isMobile } = useContext(ResponsiveContext);
  return (
    <Container>
      <Row>
        <Col
          style={
            isMobile
              ? { display: 'flex', justifyContent: 'center' }
              : { display: 'flex', justifyContent: 'flex-start' }
          }
          xs={isMobile ? 24 : 12}
          sm={isMobile ? 24 : 12}
          md={isMobile ? 24 : 12}
          lg={isMobile ? 24 : 12}
          xl={isMobile ? 24 : 12}
        >
          <Title level={4}>{CapitalizeString(data.name)}</Title>
        </Col>
        <Col
          style={
            isMobile
              ? {
                  display: 'flex',
                  marginTop: 10,
                }
              : { display: 'flex', justifyContent: 'flex-end' }
          }
          xs={isMobile ? 24 : 12}
          sm={isMobile ? 24 : 12}
          md={isMobile ? 24 : 12}
          lg={isMobile ? 24 : 12}
          xl={isMobile ? 24 : 12}
        >
          <Space
            style={
              isMobile
                ? {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }
                : { display: 'flex', justifyContent: 'flex-start' }
            }
          >
            <Link to="/atividades">
              <Button icon={<ArrowLeftOutlined />}>
                Voltar para minhas atividades
              </Button>
            </Link>
            <EditButton itemData={data} GetDataAgain={GetDataAgain} />
            <DeleteButton itemData={data} GetDataAgain={GetDataAgain} />
          </Space>
        </Col>
      </Row>
    </Container>
  );
}
