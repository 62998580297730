import React, { useContext, useState } from 'react';
// Libraries
import { Avatar, Space, Drawer } from 'antd';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Components
import Text from '~/components/typography/text';
// Assets
import imageKeyboard from '~/assets/images/icons/registerActivity1.png';

export default function Keyboard() {
  const { theme } = useContext(ThemeContext);
  const { isMobile } = useContext(ResponsiveContext);
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const ShowDrawer = () => {
    setVisibleDrawer(true);
  };
  const CloseDrawer = () => {
    setVisibleDrawer(false);
  };

  return (
    <div>
      <div
        onClick={() => ShowDrawer()}
        style={{
          padding: 10,
          display: 'flex',
          justifyContent: isMobile ? 'flex-start' : 'center',
          alignItems: 'center',
          border: `1px solid ${theme.color.borderColor}`,
          borderRadius: 10,
          cursor: 'pointer',
        }}
      >
        {isMobile ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              paddingLeft: 50,
            }}
          >
            <div style={{ marginRight: 20 }}>
              <Avatar src={imageKeyboard} shape="square" size={32} />
            </div>
            <div>
              <Text strong>Teclado</Text>
            </div>
          </div>
        ) : (
          <Space>
            <Avatar src={imageKeyboard} shape="square" size={32} />
            <Text strong>Teclado</Text>
          </Space>
        )}
      </div>
      <Drawer
        title="Teclado"
        width={'100%'}
        onClose={CloseDrawer}
        visible={visibleDrawer}
        bodyStyle={{ padding: 20 }}
      >
        <p>Drawer Body</p>
      </Drawer>
    </div>
  );
}
