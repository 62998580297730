import React from 'react';
// Libraries
import { Statistic, Row, Col } from 'antd';
// Components
import Button from '~/components/button';
import LastReceipts from './components/LastReceipts';
import Sales from './components/Sales';

export default function Money() {
  return (
    <div>
      <Row>
        <Col span={8}>
          <Statistic title="Saldo Total" value="R$ 3.475,21" />
        </Col>
        <Col span={8}>
          <Statistic title="Total de clientes" value="1.321" />
        </Col>
        <Col
          span={8}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Button text="Extrato" />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <LastReceipts />
        </Col>
        <Col span={12}>
          <Sales />
        </Col>
      </Row>
    </div>
  );
}
