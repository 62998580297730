import React, { useState, useEffect } from 'react';
// Libraries
import { message, Form } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
// Components
import Drawer from '~/components/drawer';
import Divider from '~/components/divider';
import Card from '~/components/card';
import Button from '~/components/button';
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import TextArea from '~/components/textArea';
import Select from '~/components/select';
import Checkbox from '~/components/checkbox';
import Text from '~/components/typography/text';
import DynamicTags from '~/components/tags/dynamicTags';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function CustomFields({ activityCreated }) {
    const [form] = Form.useForm();
    const [formEdit] = Form.useForm();
    const [formValues, setFormValues] = useState('');
    const [customQuestions, setCustomQuestions] = useState([]);
    const [closeAddDrawer, setCloseAddDrawer] = useState(false);
    const [closeEditDrawer, setCloseEditDrawer] = useState(false);
    const [optionsAdd, setOptionsAdd] = useState([]);
    const [optionsEdit, setOptionsEdit] = useState([]);

    useEffect(() => {
        async function GetActivityCustomQuestions() {
            const params = {
                operation: ['Partner', 'Activity', 'Show'],
                urlParams: {
                    activityId: activityCreated.id
                },
                data: {
                    pagination: {
                        limit: 100
                    }
                }
            };
            const response = await SocialMeAPI(params);
            if (response.success) {
                setCustomQuestions(response.body.activityCustomQuestions.elements)
            }
        }
        GetActivityCustomQuestions()
    }, [activityCreated])

    async function GetActivityCustomQuestions() {
        const params = {
            operation: ['Partner', 'Activity', 'Show'],
            urlParams: {
                activityId: activityCreated.id
            },
            data: {
                pagination: {
                    limit: 100
                }
            }
        };
        const response = await SocialMeAPI(params);
        if (response.success) {
            return setCustomQuestions(response.body.activityCustomQuestions.elements)
        }
    }

    async function AddQuestion(data) {
        data.options = optionsAdd;
        const params = {
            operation: ['Partner', 'Activity', 'CustomFields', 'Add'],
            urlParams: {
                activityId: activityCreated.id
            },
            data
        };
        const response = await SocialMeAPI(params);
        if (response.success) {
            message.success('Questão adicionada com sucesso!')
            GetActivityCustomQuestions();
            setCloseAddDrawer(true);
            return form.resetFields();
        }
        message.error('Alguma coisa aconteceu...')
    }

    async function DeleteQuestion(id) {
        const params = {
            operation: ['Partner', 'Activity', 'CustomFields', 'Delete'],
            urlParams: {
                activityId: activityCreated.id,
                customQuestionId: id
            },
        };
        const response = await SocialMeAPI(params);
        if (response.success) {
            message.success('Questão apagada com sucesso!')
            return GetActivityCustomQuestions();
        }
        message.error('Alguma coisa aconteceu...')
    }

    async function EditQuestion(data, id) {
        data.options = optionsEdit;
        const params = {
            operation: ['Partner', 'Activity', 'CustomFields', 'Edit'],
            urlParams: {
                activityId: activityCreated.id,
                customQuestionId: id
            },
            data
        };
        console.log(data)
        const response = await SocialMeAPI(params);
        console.log(response)
        if (response.success) {
            message.success('Questão atualizada com sucesso!')
            setCloseEditDrawer(true);
            return GetActivityCustomQuestions();
        }
        message.error('Alguma coisa aconteceu...')
    }

    function GetKindName(kind) {
        for (let i = 0; i < kindOptions.length; i++) {
            if (kindOptions[i].value === kind) {
                return kindOptions[i].label
            }

        }
    }

    const kindOptions = [
        {
            value: 'SHORT_TEXT',
            label: 'Texto curto',
        },
        {
            value: 'MEDIUM_TEXT',
            label: 'Texto médio',
        },
        {
            value: 'LONG_TEXT',
            label: 'Texto longo',
        },
        {
            value: "SINGLE_SELECTION_BOX",
            label: 'Única alternativa',
        },
        {
            value: 'SINGLE_SELECTION_LIST',
            label: 'Seleção',
        },
        {
            value: 'MULTIPLE_SELECTION_BOX',
            label: 'Múltipla escolha',
        },
        {
            value: 'SINGLE_FILE_UPLOAD',
            label: 'Upload de um único arquivo',
        },
        {
            value: 'DATE_AND_TIME',
            label: 'Data e hora',
        },
        {
            value: 'DATE',
            label: 'Data',
        },
        {
            value: 'Time',
            label: 'Hora',
        },
    ];

    return (
        <div>
            <Drawer
                Trigger={
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button type="dashed" icon={<PlusOutlined />} onClick={() => setCloseAddDrawer(false)}>Adicionar Questão</Button>
                    </div>
                }
                title="Adicionando questão"
                closeDrawer={closeAddDrawer}
                footer
                footerCancelButton
                footerMainButton={
                    <Button type="primary" onClick={() => {
                        form
                            .validateFields()
                            .then((values) => {
                                //form.resetFields();
                                AddQuestion(values);
                            })
                            .catch((info) => {
                                // console.log('Validate Failed:', info);
                            });
                    }}
                    >Adicionar questão</Button>}
            >
                <Form
                    labelCol={{
                        xs: { span: 24 },
                        sm: { span: 24 },
                    }}
                    wrapperCol={{
                        xs: { span: 24 },
                        sm: { span: 24 },
                    }}
                    form={form}
                    layout="vertical"
                    size="small"
                    onValuesChange={() => setFormValues(form.getFieldsValue())}
                    scrollToFirstError
                >
                    <FormItem
                        label="Título"
                        name={['title']}
                        rules={[
                            {
                                required: true,
                                message: 'Um título é obrigatório',
                            },
                        ]}
                        item={<Input />}
                    />
                    <FormItem
                        label="URL de arquivo de auxílio"
                        name={['avatar']}
                        item={<Input />}
                    />
                    <FormItem
                        label="Descrição"
                        name={['short_description']}
                        item={<TextArea autoSize={{ minRows: 3, maxRows: 6 }} />}
                    />
                    <FormItem
                        label="Tipo"
                        name={['kind']}
                        rules={[
                            {
                                required: true,
                                message: 'Um tipo é obrigatório',
                            },
                        ]}
                        item={<Select options={kindOptions} noDefaultValue />}
                    />
                    {
                        formValues.kind === "SINGLE_SELECTION_BOX" ||
                            formValues.kind === "SINGLE_SELECTION_LIST" ||
                            formValues.kind === "MULTIPLE_SELECTION_BOX" ?
                            <FormItem
                                label="Opções"
                                name={['options']}
                                item={<DynamicTags tags={optionsAdd} setTags={setOptionsAdd} />}
                            />
                            :
                            <></>
                    }
                    <FormItem
                        label=""
                        name="is_required"
                        valuePropName="checked"
                        item={<Checkbox text={'Obrigatório'} />}
                    />
                </Form>
            </Drawer>
            <Divider />
            <div>
                {customQuestions.map(item => (
                    <Card key={item.id} style={{ marginBottom: 5 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>
                                <Text strong>Título: {item.title}</Text>
                                <Text type="secondary">Tipo: {GetKindName(item.kind)}</Text>
                                {item.options ? item.options.map(option => (
                                    <Text type="secondary" key={option}> - {option}</Text>
                                )) : <></>}
                                <Text type="secondary">Descrição: {item.short_description}</Text>
                                <Text type="secondary">{item.is_required ? 'É obrigatório' : ''}</Text>
                            </div>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Button type="link" onClick={() => DeleteQuestion(item.id)}><DeleteOutlined /></Button>
                                    <Drawer
                                        Trigger={<Button onClick={() => setCloseEditDrawer(false)}>Editar</Button>}
                                        title="Editando questão"
                                        closeDrawer={closeEditDrawer}
                                        footer
                                        footerCancelButton
                                        footerMainButton={
                                            <Button type="primary" onClick={() => {
                                                formEdit
                                                    .validateFields()
                                                    .then((values) => {
                                                        //form.resetFields();
                                                        EditQuestion(values, item.id);
                                                    })
                                                    .catch((info) => {
                                                        // console.log('Validate Failed:', info);
                                                    });
                                            }}
                                            >Atualizar questão</Button>}
                                    >
                                        <Form
                                            labelCol={{
                                                xs: { span: 24 },
                                                sm: { span: 24 },
                                            }}
                                            wrapperCol={{
                                                xs: { span: 24 },
                                                sm: { span: 24 },
                                            }}
                                            form={formEdit}
                                            layout="vertical"
                                            size="small"
                                            scrollToFirstError
                                            initialValues={item}
                                        >
                                            <FormItem
                                                label="Título"
                                                name={['title']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Um título é obrigatório',
                                                    },
                                                ]}
                                                item={<Input />}
                                            />
                                            <FormItem
                                                label="URL de arquivo de auxílio"
                                                name={['avatar']}
                                                item={<Input />}
                                            />
                                            <FormItem
                                                label="Descrição"
                                                name={['short_description']}
                                                item={<TextArea autoSize={{ minRows: 3, maxRows: 6 }} />}
                                            />
                                            <FormItem
                                                label="Tipo"
                                                name={['kind']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Um tipo é obrigatório',
                                                    },
                                                ]}
                                                item={<Select options={kindOptions} noDefaultValue />}
                                            />
                                            {
                                                formValues.kind === "SINGLE_SELECTION_BOX" ||
                                                    formValues.kind === "SINGLE_SELECTION_LIST" ||
                                                    formValues.kind === "MULTIPLE_SELECTION_BOX" ?
                                                    <FormItem
                                                        label="Opções"
                                                        name={['options']}
                                                        item={<DynamicTags tags={optionsEdit} setTags={setOptionsEdit} />}
                                                    />
                                                    :
                                                    <></>
                                            }
                                             {
                                                item.kind === "SINGLE_SELECTION_BOX" ||
                                                    item.kind === "SINGLE_SELECTION_LIST" ||
                                                    item.kind === "MULTIPLE_SELECTION_BOX" ?
                                                    <FormItem
                                                        label="Opções"
                                                        name={['options']}
                                                        item={<DynamicTags tags={optionsEdit} setTags={setOptionsEdit} />}
                                                    />
                                                    :
                                                    <></>
                                            }
                                            <FormItem
                                                label=""
                                                name="is_required"
                                                valuePropName="checked"
                                                item={<Checkbox text={'Obrigatório'} />}
                                            />
                                        </Form>
                                    </Drawer>
                                </div>
                            </div>
                        </div>
                    </Card>
                ))}
            </div>
        </div>
    )
}