import React, { memo, useContext } from 'react';

import { ResponsiveContext } from '~/contexts/ResponsiveContext';

import Text from '~/components/typography/text';

import {
  DateInBrazilianFormat,
  HourInBrazilianFormat,
} from '~/utils/DateAndTime';

import Flex from './style';

function DateAndTimeCell({ position, timestamp }) {
  const { isMobile } = useContext(ResponsiveContext);

  const date = DateInBrazilianFormat(timestamp);
  const time = HourInBrazilianFormat(timestamp);

  return (
    <Flex position={position || 'flex-start'}>
      <Text style={isMobile && { textAlign: 'center' }}>{`Dia: ${date}`}</Text>
      <Text
        kind="secondary"
        style={isMobile && { textAlign: 'center' }}
      >{`Horário: ${time}`}</Text>
    </Flex>
  );
}

export default memo(DateAndTimeCell);
