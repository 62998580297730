import React, { memo } from 'react';

import Avatar from '~/components/avatar';
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';

import FlexBox from './style';

function PersonHeader() {
 
  return (
    <FlexBox>
      <Avatar
        style={{ marginBottom: 10 }}
        src="https://randomuser.me/api/portraits/men/12.jpg"
        size={120}
      />
      <Title level={4} style={{ margin: 0}}>Matheus Ribeiro</Title>
      <Text kind="secondary">CPF: 123.123.123-09</Text>
    </FlexBox>
  );
}

export default memo(PersonHeader);
