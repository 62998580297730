import React from 'react';
// Components
import UserDisconnectedDescription from '~/components/pageStructure/UserDisconnectedDescription';

export default function MyBusiness() {
  return (
    <UserDisconnectedDescription
      description="Nesta página você pode administrar seu negócio, produtos e serviços ofertados, editando preços, informações complementares e emitindo QR codes."
    />
  );
}
