import React from 'react';
//Libraries
import { Avatar, Row, Col } from 'antd';
//Components
import Table from '~/components/table';
import Text from '~/components/typography/text';
// Utils
import {
  WalletFormatConversion,
  CapitalizeString,
} from '~/utils/Transformations';
import {
  HourInBrazilianFormat,
  DateInBrazilianFormat,
} from '~/utils/DateAndTime';
import { PickMeCoinIcon } from '~/utils/SocialMeAssets';

export default function Content({ moves }) {
  function GetAmount(history) {
    let amount = 0;
    for (let i = 0; i < history.length; i++) {
      amount = parseInt(history[i].amount) + amount;
    }
    return `${WalletFormatConversion(amount)} Me's`;
  }
  const tableColumns = [
    {
      title: 'Cliente',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (avatar, record) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Avatar src={avatar} style={{ marginRight: 5 }} />
          <Text strong>{record.name}</Text>
        </div>
      ),
    },
    {
      title: 'Conta',
      dataIndex: 'account',
      key: 'account',
      render: (account) => <span>{account}</span>,
    },
    {
      title: 'Valor Total',
      dataIndex: 'history',
      key: 'history',
      render: (history) => <span>{GetAmount(history)}</span>,
    },
  ];
  return (
    <div>
      <Table
        dataReceived={moves}
        tableColumns={tableColumns}
        expandable={{
          expandedRowRender: (record) =>
            record.history.map((item) => (
              <Row key={item._id}>
                <Col span={6}>
                  <Text strong>{DateInBrazilianFormat(item.created_at)}</Text>
                </Col>
                <Col span={6}>
                  <Text type="secondary">
                    {HourInBrazilianFormat(item.created_at)}
                  </Text>
                </Col>
                <Col span={6}>
                  <p style={{ margin: 0 }}>
                    {CapitalizeString(
                      item.product_service_name || 'Desconhecido'
                    )}
                  </p>
                </Col>
                <Col span={6}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={PickMeCoinIcon()}
                      alt="..."
                      style={{ width: 16, marginRight: 5 }}
                    />
                    {WalletFormatConversion(item.amount)}
                  </div>
                </Col>
              </Row>
            )),
        }}
      />
    </div>
  );
}
