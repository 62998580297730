import React from 'react';
// Components
import Title from '~/components/typography/title';
//Own components
import AddButton from './components/AddButton';

export default function ProductsHeader({ GetProductsAgain }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div>
        <Title level={4}>Produtos</Title>
      </div>
      <div>
        <AddButton GetProductsAgain={GetProductsAgain} />
      </div>
    </div>
  );
}
