import React, { useEffect, useState } from 'react';
// Libraries
import { Divider } from 'antd';
import { useLocation } from 'react-router-dom';
// Own Components
import Header from './components/Header';
import Content from './components/Content';
// Components
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function LocationLinks() {
  const location = useLocation();

  const [activity, setActivity] = useState();
  const [places, setPlaces] = useState();
  const [loadingContent, setLoadingContent] = useState(true);

  useEffect(() => {
    const id = Number.parseFloat(location.pathname.split('/')[3]);
    setActivity({ id: id });
    async function GetPlaces() {
      const params = {
        operation: ['Partner', 'Activity', 'Location', 'List'],
        urlParams: {
          activityId: id,
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setPlaces(response.body.places.elements);
      }
      return setLoadingContent(false);
    }
    GetPlaces();
  }, [location]);

  async function GetPlacesAgain() {
    console.log('AGAIN');
    const params = {
      operation: ['Partner', 'Activity', 'Location', 'List'],
      urlParams: {
        activityId: activity.id,
      },
    };
    const response = await SocialMeAPI(params);
    console.log('AGAIN: ', response);
    if (response.success) {
      return setPlaces(response.body.places.elements);
    }
  }

  return (
    <div>
      <Header
        activity={activity}
        places={places}
        GetPlacesAgain={GetPlacesAgain}
      />
      <Divider />
      {loadingContent ? (
        <Loading text="Verificando locais" />
      ) : (
        <Content activity={activity} places={places} />
      )}
    </div>
  );
}
