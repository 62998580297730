import React, { useEffect, useContext, useState } from 'react';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
//Libraries
import { Row, Col } from 'antd';
import {
  TeamOutlined,
  ShoppingCartOutlined,
  DollarOutlined,
} from '@ant-design/icons';
//Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
//Own Components
import Card from './components/Card';
//Utils
import { WalletFormatConversion } from '~/utils/Transformations';

export default function Cards() {
  const { theme } = useContext(ThemeContext);
  const { CallToTheAPI } = useContext(AxiosContext);

  const [amount, setAmount] = useState(0);
  const [totalPeopleSeerved, settotalPeopleSeerved] = useState(0);
  const [salesAmount, setSalesAmount] = useState(0);

  useEffect(() => {
    async function GetMoves() {
      const params = {
        operation: ['Wallet', 'Accounts', 'Extract'],
        urlParams: {
          accountType: 'CC',
        },
        data: {
          filter: {
            Income: true,
            Outcome: false,
          },
          pagination: {
            limit: 100,
            sort: {
              order: 'desc',
              by: 'created_at',
            },
          },
        },
      };
      const response = await CallToTheAPI(params);
      console.log(response);
      if (response.success) {
        if (response.body.detailedBalance.elements.length > 0) {
          //setBalance(WalletFormatParser(response.body.balance.CC));
          //Total Sales
          const array = await response.body.detailedBalance.elements;
          console.log(array);
          let count = 0;
          let amountValue = 0;
          let arrayWithNames = [];
          for (let i = 0; i < array.length; i++) {
            if (
              array[i].intent_agent === 'SME-OFFERING-SINGLE' ||
              array[i].intent_agent === 'SME-SELL-OFFERING-SINGLE'
            ) {
              arrayWithNames.push(array[i].giver_person_name);
              count++;
              amountValue = parseInt(array[i].amount) + amountValue;
            }
          }
          setAmount(amountValue);
          setSalesAmount(count);
          //People
          const setUnico = new Set(arrayWithNames);
          const deVoltaAAray = [...setUnico];
          settotalPeopleSeerved(deVoltaAAray.length);
        }
      }
    }
    GetMoves();
  }, [CallToTheAPI]);

  return (
    <div>
      <Row>
        <Col span={6}>
          <Card
            icon={
              <DollarOutlined
                style={{ fontSize: 40, color: theme.color.primary }}
              />
            }
            text={'Total ganho'}
            value={`${WalletFormatConversion(amount)}`}
          />
        </Col>
        <Col span={6}>
          <Card
            icon={
              <TeamOutlined
                style={{ fontSize: 40, color: theme.color.primary }}
              />
            }
            text={'Pessoas atendidas'}
            value={`${totalPeopleSeerved} pessoas`}
          />
        </Col>
        <Col span={6}>
          <Card
            icon={
              <ShoppingCartOutlined
                style={{ fontSize: 40, color: theme.color.primary }}
              />
            }
            text={'Vendas realizadas'}
            value={`${salesAmount} vendas`}
          />
        </Col>
      </Row>
    </div>
  );
}
