import React, { useContext } from 'react';
// Libraries
import { message, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
import { ThemeContext } from '~/contexts/ThemeContext';

export default function Actions({ itemData, GetDataAgain }) {
  const { CallToTheAPI } = useContext(AxiosContext);
  const { theme } = useContext(ThemeContext);

  async function Delete() {
    const params = {
      operation: ['Partner', 'Activity', 'Delete'],
      urlParams: {
        activityId: itemData.id,
      },
    };
    const response = await CallToTheAPI(params);
    console.log(response)
    if (response.success) {
      GetDataAgain();
      return message.success('Atividade Deletada!');
    }
    return message.error('Alguma coisa aconteceu...');
  }

  return (
    <Popconfirm
      placement="top"
      title="Deseja deletar essa atividade?"
      onConfirm={() => Delete()}
      okText="Sim, delete agora"
      cancelText="Não"
    >
      <DeleteOutlined style={{ color: theme.color.danger }} />
      {/* 
      <Button
        type="secondary"
        shape="square"
        icon={<DeleteOutlined style={{ color: theme.color.danger }} />}
      />
      */}
    </Popconfirm>
  );
}
