import React from 'react';
// Libraries
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// Contexts
import DebugContextProvider from '~/contexts/DebugContext';
import AxiosContextProvider from '~/contexts/AxiosContext';
import AuthContextProvider from '~/contexts/AuthContext';
import BrowserContextProvider from '~/contexts/BrowserContext';
import ContractsContextProvider from '~/contexts/ContractsContext';
import GatewayContextProvider from '~/contexts/GatewayContext';
import InterfaceContextProvider from '~/contexts/InterfaceContext';
import LanguageContextProvider from '~/contexts/LanguageContext';
import ResponsiveContextProvider from '~/contexts/ResponsiveContext';
import SidebarContextProvider from '~/contexts/SidebarContext';
import ThemeContextProvider from '~/contexts/ThemeContext';
import WalletAccountContextProvider from '~/contexts/WalletAccountContext';
// Assets
import '~/assets/css/antDesignCustom.css';
// Pages
import Page from '~/pages/Partner';
//import Page from '~/pages/AuthenticationCheck';

// Libraries
// Contexts
// Standalone Components
// Components
// Own Components
// Services
// Utils
// Environments
// Assets
// Pages
// Steps
// Views
// Destructuring

export default function App() {
  return (
    <Router>
      <DebugContextProvider>
        <AxiosContextProvider>
          <BrowserContextProvider>
            <LanguageContextProvider>
              <ResponsiveContextProvider>
                <ThemeContextProvider>
                  <AuthContextProvider>
                    <WalletAccountContextProvider>
                      <InterfaceContextProvider>
                        <GatewayContextProvider>
                          <ContractsContextProvider>
                            <SidebarContextProvider>
                              <Switch>
                                <Route path="/" component={Page} />
                              </Switch>
                            </SidebarContextProvider>
                          </ContractsContextProvider>
                        </GatewayContextProvider>
                      </InterfaceContextProvider>
                    </WalletAccountContextProvider>
                  </AuthContextProvider>
                </ThemeContextProvider>
              </ResponsiveContextProvider>
            </LanguageContextProvider>
          </BrowserContextProvider>
        </AxiosContextProvider>
      </DebugContextProvider>
    </Router>
  );
}
