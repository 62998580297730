import React from 'react';
// Components
import Tabs from '~/components/tabs';
// Tabs
//import Details from './tabs/Details';
import Frequencies from './tabs/Frequencies';
import RecordFrequency from './tabs/RecordFrequency';
import LocationLinks from './tabs/LocationLinks';

export default function Content() {
  const tabsItems = [
    {
      tabPath: `/frequencias`,
      title: 'Frequências da Atividade',
      badgeCount: 0,
      component: Frequencies,
    }, 
    {
      tabPath: `/registrar-frequencia`,
      title: 'Registrar Frequência',
      badgeCount: 0,
      component: RecordFrequency,
    },
    {
      tabPath: `/gerenciamento-de-locais`,
      title: 'Gerenciamento de Locais',
      badgeCount: 0,
      component: LocationLinks,
    },
  ];
  const tabRedirect = {
    to: `/frequencias`,
  };

  return (
    <div>
      <Tabs tabsItems={tabsItems} tabRedirect={tabRedirect} parameterInTheURL />
    </div>
  );
}
