import React, { memo, useContext, useEffect, useState } from 'react';

import { ThemeContext } from '~/contexts/ThemeContext';

import Popover from '~/components/pophover';
import { Div, PopupDiv, MainTag } from './style';

function TagsCell({ tags, maxWidth }) {
  const { theme } = useContext(ThemeContext);

  const [divTags, setDivTags] = useState(tags);
  const [popupTags, setPopupTags] = useState([]);
  const [biggerThanDiv, setBiggerThanDiv] = useState(false);

  useEffect(() => {
    const div = document.getElementById('id');

    let sequelizedTags = tags;
    let tagsWidth = 0;

    div.childNodes.forEach((tag, index) => {
      if (index + 1 !== div.childElementCount) {
        tagsWidth += tag.offsetWidth;

        if (div.offsetWidth < tagsWidth) {
          sequelizedTags = sequelizedTags.filter(
            (oldTag) => oldTag.text !== tag.textContent
          );

          setBiggerThanDiv(true);
        }
      }
    });

    const popupTags = tags.filter((tag, index) => {
      return index >= sequelizedTags.length;
    });

    setPopupTags(popupTags);
    setDivTags(sequelizedTags);
  }, [tags]);

  return (
    <Div id="id" maxWidth={maxWidth || '350px'}>
      {divTags.map((tag) => {
        return (
          <MainTag key={tag.text} id={tag.color} color={tag.color}>
            {tag.text}
          </MainTag>
        );
      })}

      <Popover
        style={{ display: biggerThanDiv ? 'block' : 'none' }}
        content={
          <PopupDiv>
            {popupTags.map((tag) => {
              return (
                <MainTag key={tag.text} id={tag.color} color={tag.color}>
                  {tag.text}
                </MainTag>
              );
            })}
          </PopupDiv>
        }
        title="Mais tags"
      >
        <MainTag color={theme.color.primary}>{`+ ${popupTags.length}`}</MainTag>
      </Popover>
    </Div>
  );
}

export default memo(TagsCell);
