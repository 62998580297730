import React from 'react';
// Pages
import DONATION_PRODUCT_SERVICE from '~/pages/Partner/components/qrCodeSecondSteps/DONATION_PRODUCT_SERVICE';

export default function QRCodeSecondStep({
  QRCodeInformation,
  setQRCodeInformation,
}) {
  console.log('QRCodeInformation -> ', QRCodeInformation);

  let kind = QRCodeInformation.kind;
  switch (kind) {
    case 'DONATION_PRODUCT_SERVICE':
      return <DONATION_PRODUCT_SERVICE QRCodeInformation={QRCodeInformation} />;
    default:
      return (
        <div>
          <p>Error</p>
        </div>
      );
  }
}
