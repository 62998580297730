import React, { useContext, useEffect, useState } from 'react';
//Libraries
import { Avatar, Space, message, Form, DatePicker, TimePicker } from 'antd';
import Animate, { FadeIn } from 'animate-css-styled-components';
//Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
//Components
import Button from '~/components/button';
import Text from '~/components/typography/text';
import Divider from '~/components/divider';
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import TextArea from '~/components/textArea';
import Select from '~/components/select';
import RadioGroup from '~/components/radioGroup';
import CheckboxGroup from '~/components/checkboxGroup';
//Utils
import { CapitalizeString } from '~/utils/Transformations';
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Confirm({
  QRCodeInformation,
  CloseQRCodeReader,
  secondStepParameters,
}) {
  const { theme } = useContext(ThemeContext);
  const [form] = Form.useForm();
  const [customFields, setCustomFields] = useState([]);
  const isDebug = false;

  //console.log('1', QRCodeInformation);
  //console.log('2', secondStepParameters);

  useEffect(() => {
    async function VerifyCustomFields() {
      const params = {
        operation: ['Partner', 'Activity', 'CustomFields', 'Get'],
        urlParams: {
          activityId: secondStepParameters.activityId,
        },
      };
      const response = await SocialMeAPI(params);
      console.log(response)
      if (response.success) {
        return setCustomFields(response.body.activityCustomQuestions.elements)
      }
    }
    VerifyCustomFields()
  }, [secondStepParameters])

  async function Confirm(data) {
    if (isDebug) console.log('🚀 API: Add Frequencie');
    const params = {
      operation: ['Partner', 'Activity', 'Frequencies', 'Add'],
      urlParams: {
        activityId: secondStepParameters.activityId,
      },
      data: {
        fields: data.fields,
        person: QRCodeInformation.person_id,
        place: secondStepParameters.placeId,
      },
    };
    console.log('params -> ', params.data)
    const response = await SocialMeAPI(params);
    console.log(response)
    if (isDebug) console.log('  ⚡ API Response: ', response);
    //console.log('new: ', response.body.newFrequency);
    //console.log('qrcode: ', QRCodeInformation);
    if (response.success) {
      message.success('Frequência registrada com sucesso!');
      CloseQRCodeReader();
      let newItem = {};
      newItem.created_at = response.body.newFrequency.registered_at;
      newItem.person_name = QRCodeInformation.person_name;
      newItem.person_person_social_name =
        QRCodeInformation.person_person_social_name;
      newItem.person_avatar = QRCodeInformation.person_avatar;
      //console.log('newItem -> ', newItem)
      return secondStepParameters.AddFrequencyInTheSession(newItem);
    }
    message.error('Alguma coisa aconteceu...');
    CloseQRCodeReader();
  }

  const RenderFormItem = (item) => {
    function PrepareOptions() {
      if (item.options) {
        let data = []
        for (let i = 0; i < item.options.length; i++) {
          let newItem = {}
          newItem.value = item.options[i]
          newItem.label = item.options[i]
          data.push(newItem)
        }
        return data;
      }
    }
    function RenderItem() {
      switch (item.kind) {
        case 'SINGLE_FILE_UPLOAD':
          return <Input />
        case 'MULTIPLE_SELECTION_BOX':
          return <CheckboxGroup options={PrepareOptions()} />
        case 'SINGLE_SELECTION_LIST':
          return <Select options={PrepareOptions()} noDefaultValue />
        case 'SINGLE_SELECTION_BOX':
          return <RadioGroup options={PrepareOptions()} />
        case 'MEDIUM_TEXT':
        case 'LONG_TEXT':
          return <TextArea />
        case 'DATE':
          return <DatePicker />
        case 'TIME':
          return <TimePicker />
        case 'SHORT_TEXT':
        default:
          return <Input />
      }
    }
    return (
      <FormItem
        label={item.title}
        name={['fields', `${item.id}`]}
        helpText={item.short_description}
        rules={item.is_required ? [
          {
            required: true,
            message: 'O campo é obrigatório',
          }
        ] : []}
        item={RenderItem()}
      />
    )
  }

  return (
    <div>
      <div style={{ backgroundColor: 'white', minHeight: 'calc(100vh)', padding: 20, overflow: 'auto' }}>
        <Animate Animation={[FadeIn]} duration={['0.8s']} delay={['0.2s']}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'column',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div>
                {QRCodeInformation.person_avatar ? (
                  <Avatar src={QRCodeInformation.person_avatar} size={32} />
                ) : (
                    <Avatar
                      style={{
                        color: theme.color.textOnPrimaryBackground,
                        backgroundColor: theme.color.primary,
                      }}
                      size={32}
                    >
                      {QRCodeInformation.person_social_name
                        ? QRCodeInformation.person_social_name.charAt(0)
                        : QRCodeInformation.person_name.charAt(0)}
                    </Avatar>
                  )}
              </div>
              <div style={{ marginLeft: 10 }}>
                <Text strong>
                  {QRCodeInformation.person_social_name
                    ? CapitalizeString(QRCodeInformation.person_social_name)
                    : CapitalizeString(QRCodeInformation.person_name)}
                </Text>
              </div>
            </div>
            <Divider />
            <Form
              labelCol={{
                xs: { span: 24 },
                sm: { span: 24 },
              }}
              wrapperCol={{
                xs: { span: 24 },
                sm: { span: 24 },
              }}
              form={form}
              layout="vertical"
              size="small"
              scrollToFirstError
            >
              {customFields && customFields.map((item) => (
                <div key={item.id}>
                  {RenderFormItem(item)}
                </div>
              ))}
            </Form>
            {customFields.length !== 0 && (
              <Divider />
            )}


            <Space style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}>
              <Button size={'large'} onClick={() => CloseQRCodeReader()}>
                Cancelar
                </Button>
              <Button size={'large'} onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    //form.resetFields();
                    //form.setFieldsValue(values);
                    Confirm(values);
                  })
                  .catch((info) => {
                    //console.log('Validate Failed:', info);
                  });
              }}>
                Confirmar
                </Button>
            </Space>
          </div>
        </Animate>
      </div>
    </div>
  );
}
