import React from 'react';
// Components
import { Table } from 'antd';
import { ImageWithTextCell } from '~/components/table/cells';
// Assets
import image1 from '~/assets/images/icons/program1.svg';
import image2 from '~/assets/images/icons/program2.svg';
import image3 from '~/assets/images/icons/program3.svg';
import image4 from '~/assets/images/icons/program4.svg';

export default function TableActivity() {
  // Variables
  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <ImageWithTextCell src={record.image} text={name} />
      ),
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  const data = [
    {
      key: '1',
      name: 'John Michael',
      image: image1,
      value: '88',
    },
    {
      key: '2',
      name: 'John Michael',
      image: image2,
      value: '88',
    },
    {
      key: '3',
      name: 'John Michael',
      image: image3,
      value: '88',
    },
    {
      key: '4',
      name: 'John Michael',
      image: image4,
      value: '88',
    },
  ];
  return <Table pagination={false} columns={columns} dataSource={data} />;
}
