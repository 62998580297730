import React, { useContext, useState } from 'react';

import { QrcodeOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

import { AxiosContext } from '~/contexts/AxiosContext';

import Table from '~/components/table';
import { PrimaryTextCell, ImageWithTextCell } from '~/components/table/cells';
import Button from '~/components/button';

import PrintContent from './components/PrintContent';
import EditButton from './components/EditButton';

import { WalletFormatConversion } from '~/utils/Transformations';
import { PickMeCoinIcon } from '~/utils/SocialMeAssets';

import Flex from './style';

export default function Products({ products, GetProductsAgain }) {
  const { CallToTheAPI } = useContext(AxiosContext);
  const [visible, setVisible] = useState(false);
  const [qrCode, setQRCode] = useState();
  const [productName, setProductName] = useState();

  function ShowModal() {
    setVisible(true);
  }
  function CloseModal() {
    setVisible(false);
  }

  async function GetProductOrServiceQRCode(productServiceOffering, name) {
    const params = {
      operation: ['QRCode', 'GetProductServiceQRCode'],
      data: {
        productServiceOffering: productServiceOffering,
      },
    };
    console.log(params);
    const response = await CallToTheAPI(params);
    console.log('  ⚡ API Response (GetProducts): ', response);
    if (response.success) {
      if (response.body.newQRCode) {
        setQRCode(response.body.newQRCode._id);
      } else {
        setQRCode(response.body.QRCode._id);
      }
      setProductName(name);
      ShowModal();
    }
  }

  const columns = [
    {
      title: 'Produto',
      dataIndex: 'name',
      key: 'name',
      render: (name) => <PrimaryTextCell text={name} />,
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      key: 'value',

      render: (value) => (
        <ImageWithTextCell
          text={WalletFormatConversion(value)}
          src={PickMeCoinIcon()}
        />
      ),
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      key: 'id',
      render: (id, record) => (
        <Flex>
          <Button
            type="dashed"
            style={{ marginRight: 5 }}
            icon={<QrcodeOutlined />}
            onClick={() => GetProductOrServiceQRCode(id, record.name)}
          >
            Imprimir QR Code
          </Button>
          <EditButton record={record} GetProductsAgain={GetProductsAgain} />
        </Flex>
      ),
    },
  ];

  return (
    <div>
      <Table dataReceived={products} tableColumns={columns} />
      <Modal
        title="Imprimir QR Code do produto"
        centered
        visible={visible}
        onOk={CloseModal}
        onCancel={CloseModal}
        footer={null}
        width={'auto'}
      >
        <PrintContent
          qrCode={qrCode || '123'}
          name={productName || 'Nome do produto'}
        />
      </Modal>
    </div>
  );
}
