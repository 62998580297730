import React, { useState, useContext } from 'react';
// Libraries
import { Drawer, message, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
//Own Components
import AddForm from './components/AddForm';
import CustomFields from './components/CustomFields';
import Publish from './components/Publish';
//Components
import Button from '~/components/button';

export default function StoreButton({ GetDataAgain }) {
  const { CallToTheAPI } = useContext(AxiosContext);
  const { isMobile } = useContext(ResponsiveContext);

  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);
  const [tags, setTags] = useState([]);
  const [akas, setAkas] = useState([]);
  const [activityCreated, setActivityCreated] = useState();
  const [view, setView] = useState('Add');

  const ShowDrawer = () => {
    setVisibleDrawer(true);
  };
  const CloseDrawer = () => {
    setVisibleDrawer(false);
  };
  async function Add(data) {
    const params = {
      operation: ['Partner', 'Activity', 'Add'],
      data,
    };
    const response = await CallToTheAPI(params);
    console.log('add -> ', response);
    if (response.success) {
      GetDataAgain();
      message.success('Atividade Cadastrada!');
      setLoadingButton(false);
      if (data.has_custom_fields) {
        setActivityCreated(response.body.newActivity);
        return setView('CustomFields');
      }
      return CloseDrawer();
    }
    message.error('Alguma coisa aconteceu...');
    setLoadingButton(false);
  }
  async function PublishActivity() {
    const params = {
      operation: ['Partner', 'Activity', 'Publish'],
      urlParams:{
        activityId: activityCreated.id
      }
    };
    console.log('publish1 -> ', params);
    const response = await CallToTheAPI(params);
    console.log('publish -> ', response);
    if (response.success) {
      message.success('Atividade Publicada!');
      setLoadingButton(false);
      CloseDrawer();
      return setView('Add');
    }
    message.error('Alguma coisa aconteceu...');
    setLoadingButton(false);
  }
  const onFinish = (data) => {
    data.tags = tags;
    data.akas = akas;
    Add(data);
  };

  return (
    <>
      <Button
        text="Nova Atividade"
        onClick={() => ShowDrawer()}
        type="primary"
        icon={<PlusOutlined />}
      />

      <Drawer
        title={activityCreated ? 'Campos complementares' : "Nova Atividade"}
        width={isMobile ? '100%' : 600}
        onClose={CloseDrawer}
        visible={visibleDrawer}
        bodyStyle={{ padding: 20 }}
        footer={
          view === 'Add' ?
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button
                text="Cancelar"
                onClick={CloseDrawer}
                style={{ marginRight: 8 }}
              />

              <Button
                loading={loadingButton}
                disabled={loadingButton}
                type="primary"
                onClick={() => {
                  form
                    .validateFields()
                    .then((values) => {
                      form.resetFields();
                      onFinish(values);
                    })
                    .catch((info) => {
                      // console.log('Validate Failed:', info);
                    });
                }}
              >
                {formValues.has_custom_fields ? 'Próximo' : 'Adicionar'}
              </Button>
            </div>
            :
            view === 'CustomField' ?
              <div
                style={{
                  textAlign: 'right',
                }}
              >
                <Button
                  loading={loadingButton}
                  disabled={loadingButton}
                  type="primary"
                  onClick={() => setView('Publish')}
                >
                  Próximo
              </Button>
              </div>
              :
              <div
                style={{
                  textAlign: 'right',
                }}
              >
                <Button
                  loading={loadingButton}
                  disabled={loadingButton}
                  type="primary"
                  onClick={() => PublishActivity()}
                >
                  Publicar
              </Button>
              </div>
        }
      >
        {view === 'Add' &&
          <AddForm
            setFormValues={setFormValues}
            formValues={formValues}
            form={form}
            tags={tags}
            setTags={setTags}
            akas={akas}
            setAkas={setAkas}
          />
        }
        {view === 'CustomFields' &&
          <CustomFields activityCreated={activityCreated} />
        }
        {view === 'Publish' &&
          <Publish activityCreated={activityCreated} CloseDrawer={CloseDrawer} />
        }
      </Drawer>
    </>
  );
}
