import React, { useContext } from 'react';

import { Layout } from 'antd';
import { Route, Switch, Redirect } from 'react-router-dom';
import { BiHome, BiCoin, BiCart, BiBadgeCheck } from 'react-icons/bi';
import { GoGraph } from "react-icons/go";

import { RiStore2Line } from 'react-icons/ri';

import { ThemeContext } from '~/contexts/ThemeContext';

import Sidebar from '~/components/sidebar';
import MainNavbar from '~/components/navbar/Main';
// Pages
import Home from './pages/Home';
import Moves from './pages/Moves';
import MyBusiness from './pages/MyBusiness';
import Activities from './pages/Activities';
import PDV from './pages/PDV';
//import Covid from './pages/Covid';
import DigitalExchange from './pages/DigitalExchange';

const { Content } = Layout;

export default function Profile() {
  const { theme } = useContext(ThemeContext);
  // const { translate } = useContext(LanguageContext);

  const sidebarItems = [
    {
      index: 0,
      title: 'First',
    },
    {
      index: 1,
      title: 'Início',
      url: '/inicio',
      icon: BiHome,
    },
    {
      index: 2,
      title: 'Atividades',
      url: '/atividades',
      icon: BiBadgeCheck
    },
    {
      index: 3,
      title: 'Movimentações',
      url: '/movimentacoes',
      icon: GoGraph
    },
    {
      index: 4,
      title: 'Meu Negócio',
      url: '/meu-negocio',
      icon: RiStore2Line,
    },
    {
      index: 5,
      title: 'PDV',
      url: '/pdv',
      icon: BiCart,
    },
    {
      index:6,
      title: 'Trocados.Me',
      url: '/trocados-me',
      icon: BiCoin,
    },
    /*
    {
      index: 7,
      title: 'Covid',
      url: '/covid',
      icon: RiVirusLine
    },
    */
    {
      index: 7,
      title: 'Last',
    },
  ];

  return (
    <div>
      <Layout
        style={{
          backgroundColor: theme.color.backgroundPrimary,
          height: '100vh',
        }}
      >
        <Sidebar items={sidebarItems} />
        <Layout
          style={{
            padding: 10,
            backgroundColor: theme.color.backgroundSecondary,
          }}
        >
          <MainNavbar />
          <Content
            style={{
              overflow: 'auto',
            }}
          >
            <Switch>
              <Route path="/inicio" component={Home} />
              <Route path="/meu-negocio" component={MyBusiness} />
              <Route path="/pdv" component={PDV} />
              <Route path="/trocados-me" component={DigitalExchange} />
              <Route path="/atividades" component={Activities} />
              <Route path="/movimentacoes" component={Moves} />
              <Route exact path="*">
                <Redirect to="/inicio" />
              </Route>
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}
