import React, { useState, useContext } from 'react';
// Libraries
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
// Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
import { AuthContext } from '~/contexts/AuthContext';
// Standalone Components
import { Container, TextContainer } from './style';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Test({ CloseDrawer, GetProfileAgain }) {
  const { GetUserAgain } = useContext(AuthContext);
  const { translate } = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);

  /*
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  */

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    if (isJpgOrPng && isLt2M) {

    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      //setLoading(true);
      return;
    }
    /*
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (imageUrl) => setImageUrl(imageUrl));
    }
    */
  };

  const UploadButton = () => {
    return (
      <Container>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <TextContainer>{translate.accountManagement.newPicture.text}</TextContainer>
      </Container>
    );
  };

  async function UploadAvatar(file) {
    const data = new FormData();
    data.append('file', file);
    const params = {
      //operation: ['Profile', 'Avatar', 'Change'],
      data,
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      GetUserAgain();
      GetProfileAgain();
      setLoading(false);
      return message.success(translate.messages.profilePhotoUpdatedSuccessfully.text);
    }
    if (response.message === "The file extension is not allowed in this context") {
      message.error(translate.messages.invalidFileFormat.text);
    } else {
      message.error(translate.messages.somethingHappened.text);
    }
    return setLoading(false);
  }

  return (
    <Upload
      name="avatar"
      listType="picture-card"
      showUploadList={false}
      action={UploadAvatar}
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      <UploadButton />
    </Upload>
  );
}
