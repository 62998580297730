import React from 'react';

import Button from '~/components/button';
import Table from '~/components/table';

import {MdContentPaste} from 'react-icons/md'

import { ExternalLink } from '~/components/link';
import { ImageWithPrimaryAndSecondaryTextCell, PrimaryAndSecondaryTextCell,  ImageWithTextCell } from '~/components/table/cells';

import { PickMeCoinIcon } from '~/utils/SocialMeAssets';

import Center from './style'

export default function ColaboratorsTable() {
  // Variables
  const columns = [
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      width: '25%',
      render: (date, { time }) => (
        <PrimaryAndSecondaryTextCell secondaryText={time} primaryText={date} />
      ),
    },
    {
      title: 'Cliente',
      dataIndex: 'client',
      key: 'client',
      width: '25%',
      render: (client, { client_avatar, client_cpf}) => (
        <ImageWithPrimaryAndSecondaryTextCell  src={client_avatar} primaryText={client} secondaryText={client_cpf} />
      ),
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      key: 'value',
      align: 'center',
      width: '25%',
      render: (value) => (
        <ImageWithTextCell position="center" src={PickMeCoinIcon()} text={value} />
      ),
    },
    {
      title: '',
      dataIndex: 'id',
      id: 'key',
      render: () => (
      <Center>
        <Button style={{ paddingTop: 5, borderRadius: 0 }} icon={<MdContentPaste style={{ fontSize: 18}} />} />
        <ExternalLink to="http://pagamentos.socialme.com.br/">
          <Button style={{ borderRadius: 0 }}>F142808</Button>
        </ExternalLink>
      </Center>
     
      ),
    },
  ];

  const data = [
    {
      id: '1',
      client_avatar: 'https://uifaces.co/our-content/donated/AW-rdWlG.jpg',
      client_cpf: '123.123.123-09',
      client: 'Rogério da Silva Ribeiro',
      value: "15.37",
      date:"Data: 15/03/2021",
      time: "Hora: 17:43"
    },
    {
      id: '2',
      client_avatar: 'https://uifaces.co/our-content/donated/AW-rdWlG.jpg',
      client_cpf: '123.123.123-09',
      client: 'Rogério da Silva Ribeiro',
      value: "15.37",
      date:"Data: 15/03/2021",
      time: "Hora: 17:43"
    },
    {
      id: '3',
      client_avatar: 'https://uifaces.co/our-content/donated/AW-rdWlG.jpg',
      client_cpf: '123.123.123-09',
      client: 'Rogério da Silva Ribeiro',
      value: "15.37",
      date:"Data: 15/03/2021",
      time: "Hora: 17:43"
    },
    {
      id: '4',
      client_avatar: 'https://uifaces.co/our-content/donated/AW-rdWlG.jpg',
      client_cpf: '123.123.123-09',
      client: 'Rogério da Silva Ribeiro',
      value: "15.37",
      date:"Data: 15/03/2021",
      time: "Hora: 17:43"
    },
  ];
  return (
    <Table pagination={false} tableColumns={columns} dataReceived={data} />
  );
}
