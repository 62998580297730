import React, { useContext, memo } from 'react';
//Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Libraries
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

function AntBreadcrumb({ breadcrumbItems }) {
  const { theme } = useContext(ThemeContext);
  return breadcrumbItems === undefined ? (
    <></>
  ) : (
    <div style={{ padding: 5, marginBottom: 10 }}>
      <Breadcrumb
        separator={
          <span style={{ color: theme.color.textOnSecondaryBackground }}>
            /
          </span>
        }
      >
        {breadcrumbItems.map((item, index) =>
          item.url ? (
            <Breadcrumb.Item key={index}>
              <Link
                to={item.url}
                style={{
                  color: theme.color.textOnSecondaryBackground,
                  fontWeight: 'bold',
                }}
              >
                {item.title}
              </Link>
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item
              key={index}
              style={{ color: theme.color.textOnSecondaryBackground }}
            >
              {item.title}
            </Breadcrumb.Item>
          )
        )}
      </Breadcrumb>
    </div>
  );
}

export default memo(AntBreadcrumb);
