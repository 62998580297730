import React, { useContext } from 'react';
// Libraries
import { Avatar, Space } from 'antd';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Components
import Text from '~/components/typography/text';

export default function MethodButton({ icon, text, disabled }) {
  const { theme } = useContext(ThemeContext);
  const { isMobile } = useContext(ResponsiveContext);

  return (
    <div>
      <div
        style={{
          padding: 10,
          display: 'flex',
          justifyContent: isMobile ? 'flex-start' : 'center',
          alignItems: 'center',
          border: `1px solid ${theme.color.borderColor}`,
          borderRadius: 10,
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
      >
        {isMobile ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              paddingLeft: 50,
            }}
          >
            <div style={{ marginRight: 20 }}>
              <Avatar src={icon} shape="square" size={32} />
            </div>
            <div>
              <Text strong>{text}</Text>
            </div>
          </div>
        ) : (
          <Space>
            <Avatar src={icon} shape="square" size={32} />
            <Text strong>{text}</Text>
          </Space>
        )}
      </div>
    </div>
  );
}
