import React, { memo, useState } from 'react';

import { Form } from 'antd'

import { cpfMaskContinuos } from '~/utils/Masks';

import Input from '~/components/input';
import Button from '~/components/button';


import PersonHeader from './components/PersonHeader'
import MoreInputs from './components/MoreInputs'

import Container from './style';

function MakeExchange() {
  const [person, setPerson ] = useState('')
  const [cpf, setCpf] = useState('');

  function CreateCPForCNPJMask(value) {
    if (value.length <= 14) {
      return setCpf(cpfMaskContinuos(value));
    }
  };

  function FindPersonByCPF() {
    setPerson(cpf)
  }

  function GiveExchange(values) {
    console.log("form values", values)
  }

  return (
    <>
      <Container>
       
        <Input
          placeholder="Digite seu CPF"
          style={{ minWidth: 320 }}
          value={cpf}
          onChange={(event) => {
            if (event.target.value.length < 18) {
              CreateCPForCNPJMask(event.target.value)
            }
          }}
        />
        <Button onClick={FindPersonByCPF}>Buscar</Button>
      </Container>

      <Form layout="vertical" onFinish={GiveExchange}>
        {person &&  <PersonHeader />}
        {person &&  <MoreInputs />}
      </Form>
    </>
  );
}

export default memo(MakeExchange);
