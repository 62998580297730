import React, { useContext, memo } from 'react';

import Card from '~/components/card';
import Text from '~/components/typography/text';

import { ThemeContext } from '~/contexts/ThemeContext';

import { Cell, Space, MainCollapse, MainPanel } from './style';

function ResponsiveTable({ data, columns, expandable }) {
  const { theme } = useContext(ThemeContext);

  const cardStyle = {
    width: '85%',
    margin: '0 auto 10px auto',
    borderBottom: `1px solid ${theme.color.borderColor}`,
  };

  const bodyCardStyle = {
    padding: 5,
  };

  return (
    <>
      {data.map((item) => (
        <Card
          bordered={false}
          bodyStyle={bodyCardStyle}
          style={cardStyle}
          key={item.id}
        >
          {columns.map((column) => (
            <Cell key={column.key}>
              <Space>
                <Text strong>{column.title}</Text>
              </Space>
              {column.render(item[column.key], item)}
            </Cell>
          ))}

          {expandable ? (
            <MainCollapse>
              <MainPanel
                backgroundColor={theme.color.white}
                color={theme.color.textOnSecondaryBackground}
                header={expandable.title || 'Detalhes'}
                key="1"
              >
                {expandable.expandedRowRender(item)}
              </MainPanel>
            </MainCollapse>
          ) : (
            () => {}
          )}
        </Card>
      ))}
    </>
  );
}

export default memo(ResponsiveTable);
