import React, { useEffect, useState } from 'react';
// Libraries
import { Divider } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
// Components
import Select from '~/components/select';
import Text from '~/components/typography/text';
import Input from '~/components/input';
import Button from '~/components/button';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Header({
  activityId,
  setSelectPlace,
  setFrom,
  setTo,
  researchInProgress,
  CatchTheFrequenciesAgain,
  activities,
  loadingSelectActivities,
  SelectActivity,
}) {
  const [placesList, setPlacesList] = useState([]);
  const [loadingSelect, setLoadingSelect] = useState(true);

  useEffect(() => {
    async function GetPlaces() {
      const params = {
        operation: ['Partner', 'Activity', 'Location', 'List'],
        urlParams: {
          activityId,
        },
      };
      const response = await SocialMeAPI(params);
      console.log(response);
      if (response.success) {
        let data = [];
        response.body.places.elements.map((item) => {
          let newItem = {};
          newItem.value = item.id;
          newItem.label = item.name;
          data.push(newItem);
          return null;
        });
        setPlacesList(data);
      }
      return setLoadingSelect(false);
    }
    if (activityId) {
      GetPlaces();
    }
  }, [activityId]);

  return (
    <div>
      <Divider>Filtros</Divider>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <div style={{ marginRight: 10 }}>
          <Text>Atividades</Text>
          <Select
            loading={loadingSelectActivities}
            options={activities}
            onSelect={(value) => SelectActivity(value)}
            style={{ width: '300px' }}
          />
        </div>
        <div>
          <Text>Local</Text>
          {placesList.length > 0 ? (
            <Select
              loading={loadingSelect}
              disabled={loadingSelect}
              options={placesList}
              onSelect={(value) => setSelectPlace(value)}
              style={{ width: '300px' }}
            />
          ) : (
            <Select
              disabled={loadingSelect}
              options={placesList}
              style={{ width: '300px' }}
            />
          )}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <div style={{ marginRight: 10 }}>
            <Text>Data inicial</Text>
            <Input
              //mask="11/11/1111"
              onChange={(event) => setFrom(event.target.value)}
            />
          </div>
          <div>
            <Text>Data final</Text>
            <Input
              //mask="11/11/1111"
              onChange={(event) => setTo(event.target.value)}
            />
          </div>
        </div>
        <div>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            loading={researchInProgress}
            onClick={() => CatchTheFrequenciesAgain()}
          >
            Filtrar
          </Button>
        </div>
      </div>
    </div>
  );
}
