import React from 'react';
// Components
import { Table } from 'antd';
import { ImageWithTextCell } from '~/components/table/cells';
// Assets
import image1 from '~/assets/images/icons/service1.svg';
import image3 from '~/assets/images/icons/service3.svg';
import image4 from '~/assets/images/icons/service4.svg';
import image6 from '~/assets/images/icons/service2.svg';

export default function TableActivity() {
  // Variables
  const columns = [
    {
      title: 'Serviço',
      dataIndex: 'product',
      key: 'product',
      render: (product, record) => (
        <ImageWithTextCell
          src={record.image}
          text={product}
          paddingLeft="100px"
        />
      ),
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  const data = [
    {
      key: '1',
      product: 'Serviço 1',
      image: image1,
      value: 'R$ 112,00',
      tags: ['tag1', 'tag2'],
    },
    {
      key: '3',
      product: 'Serviço 3',
      image: image3,
      value: 'R$ 112,00',
      tags: ['tag3', 'tag3'],
    },
    {
      key: '4',
      product: 'Serviço 4',
      image: image4,
      value: 'R$ 112,00',
      tags: ['tag4', 'tag4'],
    },
    {
      key: '5',
      product: 'Serviço 5',
      image: image6,
      value: 'R$ 112,00',
      tags: ['tag5', 'tag5'],
    },
  ];
  return <Table pagination={false} columns={columns} dataSource={data} />;
}
