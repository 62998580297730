import React, { useEffect, useContext, useState } from 'react';
// Libraries
import { Divider, Card } from 'antd';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
//Own Components
import Header from './components/Header';
import Content from './components/Content';
// Components
import Loading from '~/components/loading';

export default function Products() {
  const { CallToTheAPI } = useContext(AxiosContext);
  const [loadingContent, setLoadingContent] = useState(true);
  const [products, setProducts] = useState();

  useEffect(() => {
    async function GetProducts() {
      const params = {
        operation: ['Partner', 'Product', 'Get'],
        data: {
          filter: {
            kind: 'PRODUCT',
          },
        },
      };
      const response = await CallToTheAPI(params);
      //console.log('  ⚡ API Response (GetProducts): ', response);
      if (response.success) {
        setProducts(response.body.psOfferings.elements);
      }
      setLoadingContent(false);
    }
    GetProducts();
  }, [CallToTheAPI]);

  async function GetProductsAgain() {
    const params = {
      operation: ['Partner', 'Product', 'Get'],
      data: {
        filter: {
          kind: 'PRODUCT',
        },
      },
    };
    const response = await CallToTheAPI(params);
    //console.log('  ⚡ API Response (GetProducts): ', response);
    if (response.success) {
      setProducts(response.body.psOfferings.elements);
    }
  }

  return (
   
      <Card>
        <Header GetProductsAgain={GetProductsAgain} />
        <Divider />
        {loadingContent ?
          <Loading />
          :
          <Content products={products} GetProductsAgain={GetProductsAgain} />
        }

      </Card>
   
  );
}
