import React, { memo } from 'react';

import Badge from '~/components/badge';

import Flex from './style';

function BadgeCell({ style, text, status }) {
  return (
    <Flex style={style}>
      <Badge status={status} text={text} />
    </Flex>
  );
}
export default memo(BadgeCell);
