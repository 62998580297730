import React, { useContext, useState } from 'react';
// Libraries
import { message} from 'antd';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
// Components
import Button from '~/components/button';

export default function Actions({ itemData, GetDataAgain }) {
  const { CallToTheAPI } = useContext(AxiosContext);
  const [loadingButton, setLoadingButton] = useState(false);
  console.log(itemData)

  async function PublishActivity() {
    setLoadingButton(true);
    const params = {
      operation: ['Partner', 'Activity', 'Publish'],
      urlParams: {
        activityId: itemData.id
      }
    };
    console.log('publish1 -> ', params);
    const response = await CallToTheAPI(params);
    console.log('publish -> ', response);
    if (response.success) {
      message.success('Atividade Publicada!');
      GetDataAgain();
    } else {
      message.error('Alguma coisa aconteceu...');
    }
    return setLoadingButton(false);
  }

  return (
    <Button
      type="primary"
      loading={loadingButton}
      onClick={() => PublishActivity()}
    >
      Publicar
    </Button>
  );
}
