import React from 'react';
// Components
import Title from '~/components/typography/title';
import Button from '~/components/button';

export default function Home() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        padding: 20,
      }}
    >
      <div
        style={{
          width: '80%',
        }}
      >
        <div style={{ marginBottom: 20 }}>
          <img
            src="https://socialme-assets.s3.us-east-1.amazonaws.com/images/imageTeste1.png"
            alt="..."
            style={{ display: 'block', margin: '0px auto' }}
          />
        </div>
        <div>
          <Title level={4} textAlign="center">
            Ut varius erat sed eros efficitur finibus. Nulla eu augue non felis
            scelerisque convallis. Integer varius gravida ante. Pellentesque sit
            amet purus sit amet orci eleifend scelerisque at vitae ligula.
            Quisque vitae tincidunt nisi. Fusce nisl tellus, volutpat non
            laoreet et, elementum vitae dolor.
          </Title>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            margin: '20px auto',
            flexDirection: 'column',
            width: 200,
          }}
        >
          <a href="https://accounts.socialme.com.br">
            <Button
              type="primary"
              size="large"
              style={{ margin: '0px auto', display: 'block' }}
            >
              Entrar no Social Me
            </Button>
          </a>
          <a href="https://accounts.socialme.com.br">
            <Button type="link" style={{ marginTop: 10 }}>
              Criar uma conta Social Me
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}
