import React, { useContext } from 'react';
// Libraries
import { Route, Switch, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import {
  BiHome,
  //BiBadgeCheck,
  BiCart
} from "react-icons/bi";
//import { GoGraph } from "react-icons/go";
import {
  RiStore2Line,
  //RiVirusLine 
} from "react-icons/ri";
// Components
import Sidebar from '~/components/sidebar';
import MainNavbar from '~/components/navbar/Main';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Pages
import Home from './pages/Home';
import Moves from './pages/Moves';
import MyBusiness from './pages/MyBusiness';
import Activities from './pages/Activities';
import PDV from './pages/PDV';
//Destructuring
const { Content } = Layout;

export default function ProviderDashboard() {
  const { theme } = useContext(ThemeContext);

  const sidebarItems = [
    {
      index: 0,
      title: 'First',
    },
    {
      index: 1,
      title: 'Início',
      url: '/inicio',
      icon: BiHome
    },
    {
      index: 2,
      title: 'Meu Negócio',
      url: '/meu-negocio',
      icon: RiStore2Line
    },
    {
      index: 3,
      title: 'PDV',
      url: '/pdv',
      icon: BiCart
    },
    {
      index: 4,
      title: 'Last',
    },
  ];

  return (
    <div>
      <Layout
        style={{
          backgroundColor: theme.color.backgroundPrimary,
          height: '100vh',
        }}
      >
        <Sidebar items={sidebarItems} />
        <Layout
          style={{
            padding: 10,
            backgroundColor: theme.color.backgroundSecondary,
          }}
        >
          <MainNavbar />
          <Content
            style={{
              overflow: 'auto',
            }}
          >
            <Switch>
              <Route exact path={`/`}>
                <Redirect to="/inicio" />
              </Route>
              <Route exact path={`/inicio`} component={Home} />
              <Route path={`/atividades`} component={Activities} />
              <Route path={`/movimentacoes`} component={Moves} />
              <Route path={`/meu-negocio`} component={MyBusiness} />
              <Route path={`/pdv`} component={PDV} />
              <Route exact path={`*`}>
                <Redirect to="/inicio" />
              </Route>
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}
