import React from 'react';
//Libraries
import { Link, useRouteMatch } from 'react-router-dom';
//Own Components
import Actions from './components/Actions';
//Components
import {
  ImageWithPrimaryAndSecondaryTextCell,
  BadgeCell,
} from '~/components/table/cells';
import Table from '~/components/table';
import Pagination from '~/components/pagination';

export default function Content({ data, GetDataAgain, pageParameters }) {
  const { path } = useRouteMatch();
  const tableColumns = [
    {
      title: 'Atividade',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <Link to={`${path}/${record.id}`}>
          <ImageWithPrimaryAndSecondaryTextCell
            image={record.avatar}
            primaryText={name}
            secondaryText={record.activity_type_name}
          />
        </Link>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <BadgeCell
          text={status === 'ACTIVE' ? 'Ativo' : 'Inativo'}
          status={status === 'ACTIVE' ? 'success' : 'error'}
        />
      ),
    },
    {
      title: 'Ações',
      key: 'action',
      render: (data) => <Actions GetDataAgain={GetDataAgain} data={data} />,
    },
  ];

  return (
    <div>
      <Table tableColumns={tableColumns} dataReceived={data} />
      <Pagination
        onChange={(currentPage, limit) => GetDataAgain({ currentPage, limit })}
        onShowSizeChange={(currentPage, limit) =>
          GetDataAgain({ currentPage, limit })
        }
        total={pageParameters.totalElements}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `Total ${total} items`}
      />
    </div>
  );
}
