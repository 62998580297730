import React, { memo, useContext } from 'react';

import { ResponsiveContext } from '~/contexts/ResponsiveContext';

import Avatar from '~/components/avatar';
import Text from '~/components/typography/text';

import { CapitalizeString } from '~/utils/Transformations';

import { Flex, Div } from './style';

function ImageWithPrimaryAndSecondaryTextCell({
  src,
  alt,
  shape,
  position,
  primaryText,
  secondaryText,
}) {
  const { isMobile } = useContext(ResponsiveContext);

  return (
    <Flex position={position || "flex-start"}>
      <Avatar
        style={{
          margin: isMobile ? '10px 0 0 0' : '0 10px 0 0',
        }}
        shape={shape || 'squart'}
        src={src}
        alt={alt || 'avatar'}
      >
        {primaryText ? primaryText.charAt(0) : ''}
      </Avatar>

      <Div>
        <Text>{CapitalizeString(primaryText)}</Text>
        <Text kind="secondary" style={isMobile && { textAlign: 'center' }}>
          {CapitalizeString(secondaryText)}
        </Text>
      </Div>
    </Flex>
  );
}

export default memo(ImageWithPrimaryAndSecondaryTextCell);
