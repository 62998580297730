import React, { memo, useContext, useEffect, useState } from 'react';

import { ThemeContext } from '~/contexts/ThemeContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';

import ResponsiveTable from './responsive';

import Table from './style';

function TableDefault({ dataReceived, tableColumns, setLoading, expandable }) {
  const [data, setData] = useState([]);

  const { theme } = useContext(ThemeContext);
  const { isMobile } = useContext(ResponsiveContext);

  useEffect(() => {
    async function PrepareData() {
      const treatment = [];

      await dataReceived.map((item) => {
        if (item.id) {
          item.key = item.id;
        } else {
          item.key = item._id;
        }

        return treatment.push(item);
      });

      setData(treatment);
    }

    if (dataReceived) {
      PrepareData();
    }
  }, [dataReceived, setLoading]);

  return !isMobile ? (
    <Table
      backgroundColor={theme.color.white}
      pagination={false}
      columns={tableColumns}
      dataSource={data}
      expandable={expandable}
    />
  ) : (
    <ResponsiveTable
      columns={tableColumns}
      data={data}
      expandable={expandable}
    />
  );
}

export default memo(TableDefault);
