import React, { useState } from 'react';
// Libraries
import { Card, Typography, Divider } from 'antd';
// Components
import Button from '~/components/button';
import Graph1 from './components/Graph1';
import Table from './components/Table';

const { Text } = Typography;

export default function LastReceipts() {
  const [tab, setTab] = useState('graph');
  const HandleTab = (newTab) => {
    setTab(newTab);
  };
  return (
    <div style={{ padding: 10 }}>
      <Card
        bodyStyle={{
          padding: 0,
          backgroundColor: '#f0f2f5',
          color: '#ffffff',
        }}
      >
        <div
          style={{
            padding: 10,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text strong>Últimos recebimentos</Text>
          <div>
            <Button
              onClick={() => HandleTab('graph')}
              type={tab === 'graph' ? 'primary' : 'default'}
              text="Gráfico"
              style={{ marginRight: 10 }}
            />
            <Button
              onClick={() => HandleTab('table')}
              type={tab === 'table' ? 'primary' : 'default'}
              text="Tabela"
            />
          </div>
        </div>
        <Divider />
        <div
          style={{
            padding: 10,
          }}
        >
          {tab === 'graph' ? <Graph1 /> : <Table />}
        </div>
      </Card>
    </div>
  );
}
