import React from 'react';
// Components
import Tabs from '~/components/tabs';
// Tabs
import Products from './tabs/Products';
import Services from './tabs/Services';
//import Benefits from './tabs/Benefits';

export default function PDV() {
  const tabsItems = [
    {
      tabPath: '/produtos',
      title: 'Produtos',
      badgeCount: 0,
      component: Products,
    },
    {
      tabPath: '/servicos',
      title: 'Serviços',
      badgeCount: 0,
      component: Services,
    },
    /*
    {
      tabPath: '/beneficios',
      title: 'Benefícios',
      badgeCount: 0,
      component: Benefits,
    },
    */
  ];
  const tabRedirect = {
    to: '/produtos',
  };

  return (
    <div>
      <Tabs tabsItems={tabsItems} tabRedirect={tabRedirect} />
    </div>
  );
}
