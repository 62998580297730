import styled from 'styled-components';

import System from '~/environments/System';

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => props.position};

  @media (max-width: ${`${System.ResponsiveBreakpoint}px`}) {
    flex-direction: column;
  }
`;

export default Flex;
