import React from 'react';

import Card from '~/components/card';

import Header from './components/Header';
import IntegrantionsTable from './components/ExchangeTable';

export default function DigitalExchange() {
  return (
    <>
      <Card>
        <Header />
        <IntegrantionsTable />
      </Card>
    </>
  );
}
