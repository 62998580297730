import React, { useEffect, useState } from 'react';
//Libraries
import { Divider } from 'antd';
//Own Components
import Header from './components/Header';
import Content from './components/Content';
//Components
import Loading from '~/components/loading';

export default function RecordFrequency() {
  const [loadingContent, setLoadingContent] = useState(false);
  const [frequencies, setFrequencies] = useState([]);
  const [activityId, setActivityId] = useState();

  useEffect(() => {
    const pathName = window.location.pathname;
    const activityIdValue = Number.parseFloat(pathName.split('/')[3]);
    setActivityId(activityIdValue);
  }, []);

  async function AddFrequencyInTheSession(newItem) {
    setLoadingContent(true);
    let array = frequencies;
    array.push(newItem);
    await setFrequencies(array);
    setLoadingContent(false);
  }

  return (
    <div>
      <Header
        activityId={activityId}
        AddFrequencyInTheSession={AddFrequencyInTheSession}
      />
      <Divider />
      {loadingContent ? <Loading /> : <Content data={frequencies} />}
    </div>
  );
}
