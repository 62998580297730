import React from 'react';
//Own Components
//import Actions from './components/Actions';
import { FundViewOutlined } from '@ant-design/icons';
//Components
import {
  ImageWithPrimaryAndSecondaryTextCell,
  DateAndTimeCell,
} from '~/components/table/cells';
import Table from '~/components/table';
import Drawer from '~/components/drawer';
import Button from '~/components/button';
// Own Components
import DrawerContentComponent from './components/DrawerContentComponent';

export default function Content({ data, GetDataAgain, pageParameters }) {
  const tableColumns = [
    {
      title: 'Atividade',
      dataIndex: 'activity_name',
      key: 'activity_name',
    },
    {
      title: 'Pessoa',
      dataIndex: 'person_name',
      key: 'person_name',
      render: (person_name, record) => (
        <ImageWithPrimaryAndSecondaryTextCell
          image={record.person_avatar}
          primaryText={person_name}
          secondaryText={`Identificação: ${record._id}`}
        />
      ),
    },
    {
      title: 'Data/Hora',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => <DateAndTimeCell timestamp={created_at} />,
    },
    {
      title: 'Local',
      dataIndex: 'place_name',
      key: 'place_name',
    },
    {
      title: 'Ações',
      dataIndex: 'action',
      key: 'action',
      render: (data, record) =>
        <Drawer
          Trigger={<Button icon={<FundViewOutlined />}>Ver detalhes</Button>}
          title="Detalhes da Frequência"
          DrawerContentComponentParams={record}
          DrawerContentComponent={DrawerContentComponent}
        />,
    },
  ];

  return (
    <div>
      <Table tableColumns={tableColumns} dataReceived={data} />
    </div>
  );
}
