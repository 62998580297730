import styled from 'styled-components';

import System from '~/environments/System';

export const Flex = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${props => props.position};

  @media (max-width: ${`${System.ResponsiveBreakpoint}px`}) {
    justify-content: center;
    flex-direction: column;
  }
`;

export const Div = styled.div``;
