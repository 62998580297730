import React, { memo } from 'react';

import Input from '~/components/input';
import InputNumber from '~/components/inputNumber';
import Text from '~/components/typography/text';
import Button from '~/components/button';
import FormItem from '~/components/form/FormItem';


function MoreInputs() {

  const margin = {
    marginBottom: 15
  }

  const textMargin = {
    fontWeight: 500,
    marginBottom: 5
  }

  return (
    <>
      <Text  style={textMargin}>Telefone</Text>
      <FormItem 
        label="Telefone"
        name="phone"
        initialValue=""
        item={<Input
            placeholder="Digite o celular do cliente"
            style={margin}
         />}
      />
      
      <Text style={textMargin}>Troco</Text>
      <FormItem 
        label="Troco"
        name="exchange"
        initialValue={0}
        item={ 
          <InputNumber   
            placeholder="Digite o valor do troco"
            style={margin}
            min={0}
            max={20}
          />}
        />
        
      <Button htmlType="submit" style={{ marginTop: 10 }} type="primary">Dar Trocado</Button>
    </>
  );
}

export default memo(MoreInputs);
