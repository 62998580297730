import React, { useState } from 'react';
// Libraries
import { Form, InputNumber, message } from 'antd';
// Components
import Button from '~/components/button';
import Drawer from '~/components/drawer';
import FormItem from '~/components/form/FormItem';
//import InputNumber from '~/components/inputNumber';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { WalletFormatConversion } from '~/utils/Transformations';
import { WalletFormatParser } from '~/utils/Parsers';

export default function EditButton({ record, GetProductsAgain }) {
    const [form] = Form.useForm();
    const [closeDrawer, setCloseDrawer] = useState(false);

    async function Edit(data) {
        const params = {
            operation: ['Partner', 'Product', 'Edit'],
            urlParams: {
                offeringId: record.id
            },
            data
        };
        const response = await SocialMeAPI(params);
        if (response.success) {
            message.success('Serviço Atualizado com sucesso!');
            GetProductsAgain();
        }else{
            message.error('Alguma coisa aconteceu...');
        }
        setCloseDrawer(true);
    }
    async function onFinish(data) {
        data.value = WalletFormatParser(data.value);
        Edit(data)
    }
    return (
        <div style={{ marginRight: 5 }}>
            <Drawer
                Trigger={
                    <Button type="dashed" onClick={() => setCloseDrawer(false)}>
                        Editar
                    </Button>
                }
                footer
                footerCancelButton
                footerMainButton={
                    <Button
                        type="primary"
                        onClick={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    //form.resetFields();
                                    onFinish(values);
                                })
                                .catch((info) => {
                                    //console.log('Validate Failed:', info);
                                });
                        }}
                    >
                        Atualizar
              </Button>
                }
                closeDrawer={closeDrawer}
                title="Editando Produto"
            >
                <Form
                    labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
                    wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
                    form={form}
                    size="small"
                    layout="vertical"
                    onFinish={onFinish}
                    scrollToFirstError
                    initialValues={{
                        value: WalletFormatConversion(record.value)
                    }}
                >
                    <FormItem
                        label={'Valor'}
                        name={['value']}
                        rules={[
                            {
                                required: true,
                                message: 'Um valor é obrigatório',
                            },
                        ]}
                        item={
                            <InputNumber
                                decimalSeparator={','}
                                precision={2}
                                step={1}
                                size="default"
                            />
                        }
                    />
                </Form>
            </Drawer>
        </div>
    )
}