import React, { useState, memo } from 'react';
import './style.css';
import { Tag, Input, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

function DynamicTags({ tags, setTags }) {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState();
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState();

  async function handleClose(removedTag) {
    //DeleteTag(removedTag);
    const newTags = tags.filter((tag) => tag !== removedTag);
    //console.log(newTags);
    setTags(newTags);
  }

  async function showInput() {
    setInputVisible(true);
    //Input.focus()
  }

  async function handleInputChange(e) {
    setInputValue(e.target.value.toUpperCase());
  }

  async function handleInputConfirm() {
    let newTags;

    if (inputValue && tags.indexOf(inputValue) === -1) {
      newTags = [...tags, inputValue];
      //AddTag(inputValue);
      setTags(newTags);
    }
    setInputVisible(false);
    setInputValue('');
  }

  async function handleEditInputChange(e) {
    setEditInputValue(e.target.value);
  }

  async function handleEditInputConfirm() {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;

    setTags(newTags);
    setEditInputIndex(-1);
    setEditInputValue('');
  }

  //const saveInputRef = (input) => (this.input = input);

  //const saveEditInputRef = (input) => (this.editInput = input);

  return (
    <div>
      {tags.map((tag, index) => {
        if (editInputIndex === index) {
          return (
            <Input
              //ref={saveEditInputRef}
              key={tag}
              size="small"
              className="tag-input"
              value={editInputValue}
              onChange={handleEditInputChange}
              onBlur={handleEditInputConfirm}
              onPressEnter={handleEditInputConfirm}
            />
          );
        }

        const isLongTag = tag.length > 20;

        const tagElem = (
          <Tag
            className="edit-tag"
            key={tag}
            closable={true}
            onClose={() => handleClose(tag)}
            style={{ marginBottom: 5 }}
          >
            <span
            /* 
              onDoubleClick={(e) => {
                if (index !== 0) {
                  setEditInputIndex(index);
                  setEditInputValue(tag);
                  //this.editInput.focus();
                  //e.preventDefault();
                }
              }}
              */
            >
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </span>
          </Tag>
        );
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
      {inputVisible && (
        <Input
          //ref={saveInputRef}
          type="text"
          size="small"
          className="tag-input"
          style={{ textTransform: 'uppercase' }}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
      {!inputVisible && (
        <Tag className="site-tag-plus" onClick={() => showInput()}>
          <PlusOutlined /> Nova Tag
        </Tag>
      )}
    </div>
  );
}

export default memo(DynamicTags);
