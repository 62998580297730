import React from 'react';
// Libraries
import { Row, Col, Space } from 'antd';
// Standalone components
import { Container } from './style';
//Own Components
import StoreButton from './components/StoreButton';
//Components
import Title from '~/components/typography/title';
import Search from '~/components/search';

export default function Header({ GetDataAgain, setSearchInput }) {
  return (
    <Container>
      <Row>
        <Col
          style={{ display: 'flex', justifyContent: 'flex-start' }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Title level={4}>Atividades</Title>
        </Col>
        <Col
          style={{ display: 'flex', justifyContent: 'flex-end' }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Space>
            <Search onSearch={(value) => setSearchInput(value)} />
            <StoreButton GetDataAgain={GetDataAgain} />
          </Space>
        </Col>
      </Row>
    </Container>
  );
}
