import React, { useEffect, useState } from 'react';
//Libraries
import { Divider } from 'antd';
//Own Components
import Header from './components/Header';
import Content from './components/Content';
//Components
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { DateInBrazilianFormat } from '~/utils/DateAndTime';

export default function Frequencies() {
  const [loadingContent, setLoadingContent] = useState(true);
  const [frequencies, setFrequencies] = useState();
  const [activityId, setActivityId] = useState();
  const [selectedPlace, setSelectPlace] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [researchInProgress, setResearchInProgress] = useState(false);

  useEffect(() => {
    async function CatchTheFrequencies(activityId) {
      const params = {
        operation: ['Partner', 'Activity', 'Frequencies', 'List'],
        data: {
          filter: {
            activity: activityId,
          },
          pagination: {
            sort: {
              by: 'created_at',
              order: 'desc',
            },
            limit: 100,
          },
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setFrequencies(response.body.frequencies.elements);
        return setLoadingContent(false);
      }
    }
    const pathName = window.location.pathname;
    const activityIdValue = Number.parseFloat(pathName.split('/')[3]);
    setActivityId(activityIdValue);
    CatchTheFrequencies(activityIdValue);
  }, []);

  async function CatchTheFrequenciesAgain() {
    setResearchInProgress(true);
    const params = {
      operation: ['Partner', 'Activity', 'Frequencies', 'List'],
      urlParams: {
        activityId,
      },
      data: {
        filter: {
          activity: activityId,
          place: selectedPlace,
          from: from ? DateInBrazilianFormat(`${from} 00:00:00`) : undefined,
          to: to ? DateInBrazilianFormat(`${to} 23:59:59`) : undefined,
        },
        pagination: {
          sort: {
            by: 'created_at',
            order: 'desc',
          },
          limit: 100,
        },
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      setFrequencies(response.body.frequencies.elements);
      return setResearchInProgress(false);
    }
  }

  return (
    <div>
      <Header
        activityId={activityId}
        setSelectPlace={setSelectPlace}
        setFrom={setFrom}
        setTo={setTo}
        researchInProgress={researchInProgress}
        CatchTheFrequenciesAgain={CatchTheFrequenciesAgain}
      />
      <Divider />
      {loadingContent ? (
        <Loading text="Carregando frequências" />
      ) : (
        <Content data={frequencies} GetDataAgain={CatchTheFrequenciesAgain} />
      )}
    </div>
  );
}
