import React, { memo, useContext } from 'react';

import Text from '~/components/typography/text';

import { ResponsiveContext } from '~/contexts/ResponsiveContext';

import Flex from './style';

function PrimaryAndSecondaryTextCell({ position, primaryText, secondaryText }) {
  const { isMobile } = useContext(ResponsiveContext);

  return (
    <Flex position={position || 'flex-start'}>
      <Text style={{ textAlign: isMobile ? 'center' : 'left' }}>
        {primaryText}
      </Text>
      <Text
        kind="secondary"
        style={{ textAlign: isMobile ? 'center' : 'left' }}
      >
        {secondaryText}
      </Text>
    </Flex>
  );
}

export default memo(PrimaryAndSecondaryTextCell);
