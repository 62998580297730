import React from 'react';
// Components
import UserDisconnectedDescription from '~/components/pageStructure/UserDisconnectedDescription';

export default function Home() {
  return (
    <UserDisconnectedDescription
      description="Nesta página você pode acompanhar sua situação financeira na SocialMe, visualizar os últimos lançamentos, além de poder imprimir um display para gerenciar seu negócio e facilitar a emissão de QR Code utilizados nos pagamentos dos clientes."
    />
  );
}
