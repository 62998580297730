import React, { useEffect, useState, useContext } from 'react';
// Contexts
import { AuthContext } from '~/contexts/AuthContext';
import { AxiosContext } from '~/contexts/AxiosContext';
// Libraries
import { Row, Col } from 'antd';
// Components
import FormItem from '~/components/form/FormItem';
import Select from '~/components/select';
import Input from '~/components/input';
import Text from '~/components/typography/text';

export default function SearchForm({ formValues, form }) {
  const { user } = useContext(AuthContext);
  const { CallToTheAPI } = useContext(AxiosContext);

  const [countries, setCountries] = useState([]);
  const [loadingCountries, setLoadingCountries] = useState(true);
  const [disabledStateSelect, setDisabledStateSelect] = useState(true);
  const [states, setStates] = useState([]);
  const [loadingStateSelect, setLoadingStateSelect] = useState(false);
  const [disabledCitiesSelect, setDisabledCitiesSelect] = useState(true);
  const [cities, setCities] = useState([]);
  const [loadingCitySelect, setLoadingCitySelect] = useState(false);
  const [disabledCommunitiesSelect, setDisabledCommunitiesSelect] = useState(
    true
  );
  const [communities, setCommunities] = useState([]);
  const [loadingCommunitySelect, setLoadingCommunitySelect] = useState(false);

  useEffect(() => {
    async function GetCountries() {
      const params = {
        operation: ['System', 'Location', 'Get'],
        data: {
          filter: {
            type: 'COU',
          },
          pagination: {
            sort: {
              by: 'name',
              order: 'asc',
            },
          },
        },
      };
      const response = await CallToTheAPI(params);
      if (response.success) {
        let data = [];
        response.body.countryStateCityCommunityList.elements.map((item) => {
          let newItem = {};
          if (item.name === 'BRASIL') {
            newItem.value = item.id;
            newItem.label = item.name;
            data.push(newItem);
          }
          return null;
        });
        setCountries(data);
        setLoadingCountries(false);
      }
    }
    if (user) {
      GetCountries();
    }
  }, [CallToTheAPI, user]);

  async function GetStates(countryId) {
    const params = {
      operation: ['System', 'Location', 'Get'],
      data: {
        filter: {
          type: 'ST',
          country: countryId,
        },
        pagination: {
          sort: {
            by: 'name',
            order: 'asc',
          },
          limit: 0,
        },
      },
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      let data = [];
      response.body.countryStateCityCommunityList.elements.map((item) => {
        let newItem = {};
        newItem.value = item.id;
        newItem.label = item.name;
        data.push(newItem);
        return null;
      });
      setStates(data);
      setLoadingStateSelect(false);
    }
  }

  async function GetCities(stateId) {
    const params = {
      operation: ['System', 'Location', 'Get'],
      data: {
        filter: {
          type: 'CI',
          state: stateId,
        },
        pagination: {
          sort: {
            by: 'name',
            order: 'asc',
          },
          limit: 0,
        },
      },
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      let data = [];
      response.body.countryStateCityCommunityList.elements.map((item) => {
        let newItem = {};
        newItem.value = item.id;
        newItem.label = item.name;
        data.push(newItem);
        return null;
      });
      setCities(data);
      setLoadingCitySelect(false);
    }
  }

  async function GetCommunities(cityId) {
    const params = {
      operation: ['System', 'Location', 'Get'],
      data: {
        filter: {
          type: 'COM',
          city: cityId,
        },
        pagination: {
          sort: {
            by: 'name',
            order: 'asc',
          },
          limit: 0,
        },
      },
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      let data = [];
      response.body.countryStateCityCommunityList.elements.map((item) => {
        let newItem = {};
        newItem.value = item.id;
        newItem.label = item.name;
        data.push(newItem);
        return null;
      });
      setCommunities(data);
      setLoadingCommunitySelect(false);
    }
  }

  async function SelectCountry(data) {
    form.setFieldsValue({ state: '' });
    form.setFieldsValue({ city: '' });
    form.setFieldsValue({ community: '' });
    setStates([]);
    setDisabledStateSelect(false);
    setLoadingStateSelect(true);
    GetStates(data.value);
  }

  async function SelectState(data) {
    form.setFieldsValue({ city: '' });
    form.setFieldsValue({ community: '' });
    setCities([]);
    setDisabledCitiesSelect(false);
    setLoadingCitySelect(true);
    GetCities(data.value);
  }

  async function SelectCity(data) {
    form.setFieldsValue({ community: '' });
    setCommunities([]);
    setDisabledCommunitiesSelect(false);
    setLoadingCommunitySelect(true);
    GetCommunities(data.value);
  }

  return (
    <div style={{ padding: 10 }}>
      <Row>
        <Text>Filtros de busca</Text>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ padding: 5 }}>
          <FormItem
            label="País"
            name={['country']}
            style={{ marginRight: 10 }}
            item={
              <Select
                options={countries}
                loading={loadingCountries}
                onSelect={(value, data) => SelectCountry(data)}
              />
            }
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ padding: 5 }}>
          <FormItem
            label="Estado"
            name={['state']}
            style={{ marginRight: 10 }}
            item={
              <Select
                options={states}
                loading={loadingStateSelect}
                disabled={disabledStateSelect}
                onSelect={(value, data) => SelectState(data)}
              />
            }
          />
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ padding: 5 }}>
          <FormItem
            label="Cidade"
            name={['city']}
            item={
              <Select
                options={cities}
                loading={loadingCitySelect}
                disabled={disabledCitiesSelect}
                onSelect={(value, data) => SelectCity(data)}
              />
            }
          />
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ padding: 5 }}>
          <FormItem
            label="Comunidade"
            name={['community']}
            item={
              <Select
                options={communities}
                loading={loadingCommunitySelect}
                disabled={disabledCommunitiesSelect}
                //onSelect={(value, data) => SelectCity(data)}
              />
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ padding: 5 }}>
          <FormItem
            label="Nome ou parte do nome"
            name={['searchString']}
            item={<Input />}
          />
        </Col>
      </Row>
    </div>
  );
}
