import React from 'react';
// Components
import UserDisconnectedDescription from '~/components/pageStructure/UserDisconnectedDescription';

export default function PDV() {
  return (
    <UserDisconnectedDescription
      description="Nesta página você pode visualizar sua frente de caixa, seus clientes, os produtos e serviços comprados, a data de compra e as formas de pagamento."
    />
  );
}
