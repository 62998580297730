import React, { useEffect, useContext, useState } from 'react';

import { format, subDays } from 'date-fns';

import { AxiosContext } from '~/contexts/AxiosContext';

import Card from '~/components/card'

import Content from './components/Content';

export default function Sales() {
  const { CallToTheAPI } = useContext(AxiosContext);

  const [moves, setMoves] = useState([]);

  useEffect(() => {
    async function GetMoves() {
      const today = format(new Date(), 'yyyy-MM-dd');
      const yesterday = format(subDays(new Date(), 1), 'yyyy-MM-dd');
      const params = {
        operation: ['Wallet', 'Accounts', 'Extract'],
        urlParams: {
          accountType: 'CC',
        },
        data: {
          filter: {
            Income: true,
            Outcome: false,
            from: `${yesterday} 00:00:01`,
            to: `${today} 23:59:59`,
          },
          pagination: {
            limit: 100,
            sort: {
              order: 'desc',
              by: 'created_at',
            },
          },
        },
      };
      console.log('params -> ', params.data);
      const response = await CallToTheAPI(params);
      console.log('  ⚡ API Response (GetMoves): ', response);
      if (response.success) {
        const array = await response.body.detailedBalance.elements;
        let arrayWithKind = [];
        for (let i = 0; i < array.length; i++) {
          if (
            array[i].intent_agent === 'SME-OFFERING-SINGLE' ||
            array[i].intent_agent === 'SME-SELL-OFFERING-SINGLE'
          ) {
            arrayWithKind.push(array[i]);
          }
        }

        let arrayFinal = [];

        for (let i = 0; i < arrayWithKind.length; i++) {
          let check = (arrayFinal) =>
            arrayFinal.name === arrayWithKind[i].giver_person_name;
          let have = arrayFinal.find(check);
          if (have) {
            for (let j = 0; j < arrayFinal.length; j++) {
              if (arrayFinal[j].name === arrayWithKind[i].giver_person_name) {
                arrayFinal[j].history.push(arrayWithKind[i]);
              }
            }
          } else {
            let newItem = {};
            newItem.id = arrayWithKind[i]._id;
            newItem.name = arrayWithKind[i].giver_person_name;
            newItem.avatar = arrayWithKind[i].giver_person_avatar;
            newItem.account = `${arrayWithKind[i].giver_account_number}${arrayWithKind[i].giver_account_vd}`;
            newItem.history = [];
            newItem.history.push(arrayWithKind[i]);
            arrayFinal.push(newItem);
          }
        }
        console.log('Array Final -> ', arrayFinal);
        setMoves(arrayFinal);
      }
    }
    GetMoves();
  }, [CallToTheAPI]);

  return (
    <Card>
      <Content moves={moves} />
    </Card>
  );
}
