import React from 'react';
import ReactToPrint from 'react-to-print';
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';
import Button from '~/components/button';
import QRCode from 'qrcode.react';
import { CapitalizeString } from '~/utils/Transformations';

class ComponentToPrint extends React.Component {
  render() {
    console.log(this.props.place);
    return (
      <div
        style={{
          width: 840,
          height: 1185,
          backgroundImage:
            "url('https://socialme-assets.s3.us-east-1.amazonaws.com/images/qrCode/DisplayParaAtividade.jpg')",
          backgroundPosition: 'center top',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%',
          padding: '513px 0px 0px 92px',
        }}
      >
        <div
          style={{
            width: 655,
            height: 411,
            borderRadius: '50px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'column',
            padding: 5,
          }}
        >
          <div>
            <Title level={2}>{this.props.activityInformation.name}</Title>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              padding: 10,
            }}
          >
            <QRCode
              value={this.props.qrCode}
              size={300}
              bgColor="#ffffff"
              fgColor="#303030"
              level="L"
              includeMargin={false}
              renderAs="svg"
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
          >
            <Text strong>
              Executor:{' '}
              {CapitalizeString(this.props.activityInformation.created_by_name)}
            </Text>
            <Text strong>Local: {CapitalizeString(this.props.place.name)}</Text>
          </div>
        </div>
      </div>
    );
  }
}

class Example extends React.Component {
  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => (
            <Button type="primary" style={{ marginBottom: 10 }}>
              Confirmar impressão
            </Button>
          )}
          content={() => this.componentRef}
        />
        <ComponentToPrint
          qrCode={this.props.qrCode}
          place={this.props.place}
          activityInformation={this.props.activityInformation}
          ref={(el) => (this.componentRef = el)}
        />
      </div>
    );
  }
}

export default Example;
