import React from 'react';
// Libraries
import { Form } from 'antd';
// Own Components
import Questions from './components/Questions';

export default function AddForm({ formValues, setFormValues, form, tags, setTags, akas, setAkas }) {
  return (
    <Form
      labelCol={{
        xs: { span: 24 },
        sm: { span: 24 },
      }}
      wrapperCol={{
        xs: { span: 24 },
        sm: { span: 24 },
      }}
      form={form}
      layout="vertical"
      size="small"
      onValuesChange={() => setFormValues(form.getFieldsValue())}
      scrollToFirstError
      initialValues={{
        visibility: 'PUBLIC',
        status: 'ACTIVE',
      }}
    >
      <Questions
        formValues={formValues}
        form={form}
        tags={tags}
        setTags={setTags}
        akas={akas}
        setAkas={setAkas}
      />
    </Form>
  );
}
