import React from 'react';
//Components
import Card from '~/components/card';
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';

export default function CardHome({ icon, text, value }) {
  return (
    <div style={{ padding: 5 }}>
      <Card bodyStyle={{ padding: 10 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div>{icon}</div>
          <div>
            <Title level={4}>{value}</Title>
            <Text>{text}</Text>
          </div>
        </div>
      </Card>
    </div>
  );
}
