import React from 'react';
// Components
import Tabs from '~/components/tabs';
import FloatingPDVButton from '~/pages/Partner/components/floatingPDVButton';
// Tabs
import Sales from './tabs/Sales';
import SalesOfTheDay from './tabs/SalesOfTheDay';

export default function PDV() {
  const tabsItems = [
    {
      tabPath: '/vendas',
      title: 'Vendas',
      badgeCount: 0,
      component: Sales,
    },
    {
      tabPath: '/vendas-do-dia',
      title: 'Vendas do dia',
      badgeCount: 0,
      component: SalesOfTheDay,
    },
    /*
    {
      tabPath: '/depositos-recebidos-no-dia',
      title: 'Depósitos recebidos no dia',
      badgeCount: 0,
      component: Sell,
    },
    */
  ];
  const tabRedirect = {
    to: '/vendas',
  };

  return (
    <div>
      <FloatingPDVButton />
      <Tabs tabsItems={tabsItems} tabRedirect={tabRedirect} />
    </div>
  );
}
