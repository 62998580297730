import React, { useEffect, useState } from 'react';
// Libraries
import { SearchOutlined } from '@ant-design/icons';
// Standalone Components
import { Container, Section, SectionGroup } from './style';
// Components
import Button from '~/components/button';
import Loading from '~/components/loading';
import Divider from '~/components/divider';
import Text from '~/components/typography/text';
import Avatar from '~/components/avatar';
import Modal from '~/components/modal';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { CapitalizeString } from '~/utils/Transformations';
import { DateInBrazilianFormat, HourInBrazilianFormat } from '~/utils/DateAndTime';

export default function DrawerContentComponent({ propertys }) {
    const [loadingContent, setLoadingContent] = useState(true);
    const [frequencyInformation, setFrequencyInformation] = useState();

    useEffect(() => {
        async function GetFrequencyInformation() {
            const params = {
                operation: ['Activity', 'Frequency', 'Show'],
                urlParams: {
                    frequencyId: propertys._id
                }
            };
            const response = await SocialMeAPI(params);
            console.log(response)
            if (response.success) {
                setFrequencyInformation(response.body.activityFrequency)
                return setLoadingContent(false);
            }
        }
        GetFrequencyInformation();
    }, [propertys])

    if (loadingContent) {
        return (
            <div>
                <Loading />
            </div>
        )
    }
    return (
        <Container>
            <Divider>Atividade</Divider>
            <Section>
                <SectionGroup >
                    <Avatar src={frequencyInformation.activity_type_avatar} size={64} />
                </SectionGroup >
                <SectionGroup >
                    <Text strong>Nome da atividade: {CapitalizeString(frequencyInformation.activity_name)}</Text>
                    <Text type="secondary">Tipo: {CapitalizeString(frequencyInformation.activity_type_name)}</Text>
                </SectionGroup >
            </Section>
            <SectionGroup>
                <Text>{frequencyInformation.activity_short_description}</Text>
            </SectionGroup>
            <SectionGroup>
                {frequencyInformation.activity_saves_in_block_chain && <Text strong>Salvo em blockchain</Text>}
            </SectionGroup>
            <SectionGroup>
                <Text>Visibilidade: {frequencyInformation.activity_visibility === 'PUBLIC' ? 'Público' : 'Privado'}</Text>
            </SectionGroup>
            <SectionGroup>
                {frequencyInformation.activity_proof_of_attendance &&
                    <div>
                        <Text strong>Possui documento comprovante</Text>
                        <Modal
                            Trigger={<Button type="primary" icon={<SearchOutlined />} size="small">Ver documento</Button>}
                        >
                            {frequencyInformation.proof_of_attendance}
                        </Modal>
                    </div>
                }
            </SectionGroup>
            {frequencyInformation.fields && frequencyInformation.fields.map(item => (
                <SectionGroup key={item.id}>
                    <Text strong>{item.title} </Text>
                    <Text type="secondary">{item.answer}</Text>
                </SectionGroup>
            ))}
            <Divider>Pessoa</Divider>
            <Section>
                <SectionGroup >
                    <Avatar src={frequencyInformation.person_avatar} size={64} />
                </SectionGroup >
                <SectionGroup >
                    <Text strong>{CapitalizeString(frequencyInformation.person_name)}</Text>
                </SectionGroup >
            </Section>
            <Divider>Local da atividade</Divider>
            <Section>
                <SectionGroup >
                    <Avatar src={frequencyInformation.place_avatar} size={64} />
                </SectionGroup >
                <SectionGroup >
                    <Text strong>{CapitalizeString(frequencyInformation.place_name)}</Text>
                </SectionGroup >
            </Section>
            <Divider>Quem registrou</Divider>
            <Section>
                <SectionGroup >
                    <Avatar src={frequencyInformation.registered_by_avatar} size={64} />
                </SectionGroup >
                <SectionGroup >
                    <Text strong>{CapitalizeString(frequencyInformation.registered_by_name)}</Text>
                    <Text type="secondary">Data: {DateInBrazilianFormat(frequencyInformation.registered_at)}</Text>
                    <Text type="secondary">Hora: {HourInBrazilianFormat(frequencyInformation.registered_at)}</Text>
                </SectionGroup >
            </Section>
        </Container>
    )
}