import React from 'react';
// Libraries
import { LinkOutlined } from '@ant-design/icons';
// Components
import Table from '~/components/table';
import Button from '~/components/button';
import { ImageWithPrimaryAndSecondaryTextCell } from '~/components/table/cells';
import Pagination from '~/components/pagination';

export default function ResultContent({
  searchResult,
  pageParameters,
  SearchPlacesAgain,
  LinkLocation,
  loadingButton,
  places,
}) {
  const RenderAddressInformation = (record) => {
    return `${record.address_designation || ''} ${
      record.address_address ? record.address_address + ',' : ''
    } ${record.address_number ? record.address_number + ',' : ''} ${
      record.address_neighborhood ? record.address_neighborhood + '.' : ''
    } ${record.address_city_name ? record.address_city_name + ' -' : ''}
     ${record.address_state_name ? record.address_state_name + ' -' : ''} ${
      record.address_country_name ? record.address_country_name : ''
    }`;
  };
  function LocationAlreadyLinked(resultLocationId) {
    for (let i = 0; i < places.length; i++) {
      if (resultLocationId === places[i].id) return true;
    }
    return false;
  }
  const tableColumns = [
    {
      title: 'Local',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <ImageWithPrimaryAndSecondaryTextCell
          image={record.avatar}
          primaryText={name}
          secondaryText={RenderAddressInformation(record)}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (action, record) =>
        LocationAlreadyLinked(record.id) ? (
          <Button
            disabled={true}
            type="primary"
            icon={<LinkOutlined />}
            onClick={() => LinkLocation(record)}
          >
            Local já vinculado
          </Button>
        ) : (
          <Button
            disabled={loadingButton}
            loading={loadingButton}
            type="primary"
            icon={<LinkOutlined />}
            onClick={() => LinkLocation(record)}
          >
            Vincular local
          </Button>
        ),
    },
  ];
  return (
    <div>
      <div>
        <Table dataReceived={searchResult} tableColumns={tableColumns} />
      </div>
      <div>
        <Pagination
          onChange={(currentPage, limit) =>
            SearchPlacesAgain({ currentPage, limit })
          }
          onShowSizeChange={(currentPage, limit) =>
            SearchPlacesAgain({ currentPage, limit })
          }
          total={pageParameters.totalElements}
          showSizeChanger
          showQuickJumper
          showTotal={(total) => `${total} registros no total`}
        />
      </div>
    </div>
  );
}
