import React from 'react';
// Components
import { Card, Typography, Divider } from 'antd';
import Breadcrumb from '~/components/breadcrumb';
import Tabs from '~/components/tabs';
// Tabs
import Money from './tabs/Money';
import Mes from './tabs/Mes';

const { Title } = Typography;

const breadcrumbItems = [
  {
    title: 'Painel do Fornecedor',
  },
  {
    title: 'Minhas movimentações',
  },
];

const tabsItems = [
  {
    title: 'Dinheiro',
    component: Money,
    tabPath: '/dinheiro',
  },
  {
    title: "Me's",
    component: Mes,
    tabPath: '/mes',
  },
];

const tabRedirect = {
  to: '/dinheiro',
};

export default function Moves() {
  // Variables
  return (
    <div>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <Card>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Title level={4}>Minhas movimentações</Title>
        </div>
        <Divider />
        <Tabs tabsItems={tabsItems} tabRedirect={tabRedirect} />
      </Card>
    </div>
  );
}
