import React, { useContext } from 'react';
//Contexts
import { AuthContext } from '~/contexts/AuthContext';
//Pages
import PartnerDescription from './PartnerDescription';
import PartnerDashboard from './PartnerDashboard';

export default function PartnerCheck() {
  const { user } = useContext(AuthContext);
  return user ? <PartnerDashboard /> : <PartnerDescription />;
}
