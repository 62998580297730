import React, { useEffect, useState } from 'react';
// Libraries
import { Divider, Col, Row } from 'antd';
// Components
import Select from '~/components/select';
import QRCodeReader from '~/components/qrcode/reader';
// Own Components
import MethodButton from './components/MethodButton';
import QRCodeSecondStep from './components/QRCodeSecondStep';
import SocialMeCard from './components/SocialMeCard';
import Biometry from './components/Biometry';
import Keyboard from './components/Keyboard';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
// Assets
import QRCodeIcon from '~/assets/images/icons/registerActivity4.png';

export default function Header({ activityId, AddFrequencyInTheSession }) {
  const [placesList, setPlacesList] = useState([]);
  const [placeId, setPlaceId] = useState();
  const [loadingSelect, setLoadingSelect] = useState(true);

  useEffect(() => {
    async function GetPlaces() {
      const params = {
        operation: ['Partner', 'Activity', 'Location', 'List'],
        urlParams: {
          activityId,
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        let data = [];
        response.body.places.elements.map((item) => {
          let newItem = {};
          newItem.value = item.id;
          newItem.label = item.name;
          data.push(newItem);
          return null;
        });
        setPlacesList(data);
      }
      return setLoadingSelect(false);
    }
    if (activityId) {
      GetPlaces();
    }
  }, [activityId]);

  return (
    <div>
      <Divider>Escolha o local</Divider>
      <div>
        <Select
          loading={loadingSelect}
          options={placesList}
          onSelect={(value) => setPlaceId(value)}
        />
      </div>
      <Divider>Escolha o método</Divider>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={6}
          lg={6}
          xl={6}
          style={{ padding: '0px 5px', marginTop: 10 }}
        >
          <SocialMeCard />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={6}
          lg={6}
          xl={6}
          style={{ padding: '0px 5px', marginTop: 10 }}
        >
          <Biometry />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={6}
          lg={6}
          xl={6}
          style={{ padding: '0px 5px', marginTop: 10 }}
        >
          <QRCodeReader
            Trigger={
              <MethodButton
                icon={QRCodeIcon}
                text="QR Code"
                disabled={placeId ? false : true}
              />
            }
            SecondStepComponent={QRCodeSecondStep}
            neverOpen={placeId ? false : true}
            title={'Registro de frequência com QR Code'}
            kind={'PERSONAL'}
            secondStepParameters={{
              placeId: placeId,
              activityId: activityId,
              AddFrequencyInTheSession: AddFrequencyInTheSession,
            }}
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={6}
          lg={6}
          xl={6}
          style={{ padding: '0px 5px', marginTop: 10 }}
        >
          <Keyboard />
        </Col>
      </Row>
    </div>
  );
}
