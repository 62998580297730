import React from 'react';
// Libraries
import { Route, Switch, useRouteMatch } from 'react-router-dom';
// Pages
import ListOfMyActivities from './pages/ListOfMyActivities';
import DetailsOfASingleActivity from './pages/DetailsOfASingleActivity';

export default function Relationships() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={ListOfMyActivities} />
      <Route
        path={`${path}/:activityId`}
        component={DetailsOfASingleActivity}
      />
    </Switch>
  );
}
