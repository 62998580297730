import React, { useEffect, useState, useContext } from 'react';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
//Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import TextArea from '~/components/textArea';
import Select from '~/components/select';
import Checkbox from '~/components/checkbox';
import DynamicTags from '~/components/tags/dynamicTags';

export default function ProductsFormItems({ tags, setTags, akas, setAkas }) {
  const { CallToTheAPI } = useContext(AxiosContext);

  const [activityTypes, setActivityTypes] = useState([]);

  useEffect(() => {
    async function GetActivityTypes() {
      const params = {
        operation: ['System', 'ActivityType', 'ListSimple'],
      };
      const response = await CallToTheAPI(params);
      if (response.success) {
        let array = response.body.activityTypes.elements;
        const treatment = [];
        await array.map((item) => {
          let newItem = {};
          newItem.value = item.id;
          newItem.label = item.name;
          return treatment.push(newItem);
        });
        await setActivityTypes(treatment);
      }
    }
    GetActivityTypes();
  }, [CallToTheAPI]);

  const VisibilityOptions = [
    {
      value: 'PUBLIC',
      label: 'Público',
    },
    {
      value: 'PRIVATE',
      label: 'Privado',
    },
  ];

  return (
    <div>
      <FormItem
        label="Nome"
        name={['name']}
        rules={[
          {
            required: true,
            message: 'Um nome é obrigatório',
          },
        ]}
        item={<Input />}
      />

      {activityTypes.length > 0 ? (
        <FormItem
          label="Tipo de Atividade"
          name={['activity_type']}
          rules={[
            {
              required: true,
              message: 'Um tipo é obrigatório',
            },
          ]}
          item={<Select options={activityTypes} />}
        />
      ) : (
        <FormItem
          label="Tipo de Atividade"
          rules={[
            {
              required: true,
              message: 'Um tipo é obrigatório',
            },
          ]}
          item={<Select options={[]} disabled={true} loading={true} />}
        />
      )}

      <FormItem
        label="Descrição Curta"
        name={['short_description']}
        item={<TextArea autoSize={{ minRows: 2, maxRows: 2 }} />}
      />
      <FormItem
        label="Descrição Completa"
        name={['description']}
        item={<TextArea autoSize={{ minRows: 3, maxRows: 6 }} />}
      />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <FormItem
          label="Tipo"
          name={['visibility']}
          item={<Select options={VisibilityOptions} />}
        />
        <FormItem
          label="Status"
          name="status"
          valuePropName="checked"
          item={<Checkbox text={'Ativo'} />}
        />
      </div>

      <FormItem label="Avatar" name={['avatar']} item={<Input />} />
      <FormItem
        label="Tags"
        name={['tags']}
        item={<DynamicTags tags={tags} setTags={setTags} />}
      />
      <FormItem
        label="AKAS"
        name={['akas']}
        item={<DynamicTags tags={akas} setTags={setAkas} />}
      />
    </div>
  );
}
