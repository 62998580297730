import React, { useContext, useState } from 'react';
// Libraries
import { Drawer, Form } from 'antd';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Own Components
import SearchForm from './components/SearchForm';
import ResultContent from './components/ResultContent';
// Components
import Button from '~/components/button';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Header({ activity, places, GetPlacesAgain }) {
  const { isMobile } = useContext(ResponsiveContext);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState('');
  const [searchResult, setSearchResult] = useState();
  const [pageParameters, setPageParameters] = useState({
    currentPage: 1,
    totalElements: 0,
    limit: 10,
  });

  const ShowDrawer = () => {
    setVisibleDrawer(true);
  };
  const CloseDrawer = () => {
    setSearchResult();
    form.resetFields();
    setVisibleDrawer(false);
  };

  async function SearchPlaces(data) {
    const params = {
      operation: ['System', 'Location', 'Search'],
      data: {
        filter: {
          country: data.country,
          state: data.state,
          city: data.city,
          community: data.community,
          searchString: data.searchString,
        },
        pagination: {
          limit: pageParameters.limit,
          page: pageParameters.currentPage,
          sort: {
            by: 'name',
            order: 'asc',
          },
        },
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      setPageParameters({
        currentPage: response.body.places.currentPage,
        totalElements: response.body.places.totalElements,
        limit: response.body.places.limit,
      });
      setSearchResult(response.body.places.elements);
    }
    return setLoadingButton(false);
  }

  const onFinish = (data) => {
    setLoadingButton(true);
    SearchPlaces(data);
  };

  const RenderButton = () => {
    if (searchResult) {
      return (
        <Button
          loading={loadingButton}
          disabled={loadingButton}
          type="dashed"
          onClick={() => {
            setSearchResult();
          }}
        >
          Pesquisar novamente
        </Button>
      );
    }
    return (
      <Button
        loading={loadingButton}
        disabled={loadingButton}
        type="primary"
        onClick={() => {
          form
            .validateFields()
            .then((values) => {
              //form.resetFields();
              onFinish(values);
            })
            .catch((info) => {
              // console.log('Validate Failed:', info);
            });
        }}
      >
        Pesquisar
      </Button>
    );
  };

  async function SearchPlacesAgain(newPageParameters) {
    setLoadingButton(true);
    const params = {
      operation: ['Location', 'Search'],
      data: {
        filter: {
          country: formValues.country,
          state: formValues.state,
          city: formValues.city,
          community: formValues.community,
          searchString: formValues.searchString,
        },
        pagination: {
          limit: newPageParameters
            ? newPageParameters.limit
            : pageParameters.limit,
          page: newPageParameters
            ? newPageParameters.currentPage
            : pageParameters.currentPage,
          sort: {
            by: 'name',
            order: 'asc',
          },
        },
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      setPageParameters({
        currentPage: response.body.places.currentPage,
        totalElements: response.body.places.totalElements,
        limit: response.body.places.limit,
      });
      setSearchResult(response.body.places.elements);
    }
    return setLoadingButton(false);
  }

  async function LinkLocation(placeInformation) {
    setLoadingButton(true);
    const params = {
      operation: ['Partner', 'Activity', 'Location', 'Add'],
      urlParams: {
        placeId: placeInformation.id,
        activityId: activity.id,
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      GetPlacesAgain();
    }
    return setLoadingButton(false);
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
      }}
    >
      <Button type="dashed" onClick={() => ShowDrawer()}>
        Vincular novo local da atividade
      </Button>

      <Drawer
        title={`Vinculando novo local`}
        width={isMobile ? '100%' : '100%'}
        onClose={CloseDrawer}
        visible={visibleDrawer}
        bodyStyle={{ padding: 0 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={CloseDrawer} style={{ marginRight: 8 }}>
              Cancelar
            </Button>

            {RenderButton()}
          </div>
        }
      >
        <Form
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
          }}
          form={form}
          layout="vertical"
          size="small"
          onValuesChange={() => setFormValues(form.getFieldsValue())}
          scrollToFirstError
        >
          {searchResult ? (
            <ResultContent
              searchResult={searchResult}
              pageParameters={pageParameters}
              SearchPlacesAgain={SearchPlacesAgain}
              LinkLocation={LinkLocation}
              loadingButton={loadingButton}
              places={places}
            />
          ) : (
            <SearchForm formValues={formValues} form={form} />
          )}
        </Form>
      </Drawer>
    </div>
  );
}
