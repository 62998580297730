import React, { useState, useContext } from 'react';
//Libraries
import { PlusOutlined } from '@ant-design/icons';
import { Drawer, Form } from 'antd';
//Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
//Components
import Button from '~/components/button';
//Own Components
import AddForm from './components/AddForm';

export default function AddButton({ GetProductsAgain }) {
  const { isMobile } = useContext(ResponsiveContext);

  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  function ShowDrawer() {
    setVisible(true);
  }
  function CloseDrawer() {
    setVisible(false);
  }

  return (
    <div>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => ShowDrawer()}
      >
        Novo Serviço
      </Button>
      <Drawer
        title="Adicionando Serviço"
        placement="right"
        width={isMobile ? '100%' : 600}
        onClose={CloseDrawer}
        visible={visible}
        bodyStyle={{ padding: 20 }}
      >
        <AddForm
          form={form}
          CloseDrawer={CloseDrawer}
          GetProductsAgain={GetProductsAgain}
        />
      </Drawer>
    </div>
  );
}
