import React from 'react';
import ReactToPrint from 'react-to-print';
import Title from '~/components/typography/title';
import Button from '~/components/button';
import QRCode from 'qrcode.react';

class ComponentToPrint extends React.Component {
  render() {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          width: 840,
          height: 1185,
          backgroundImage:
            "url('https://socialme-assets.s3.us-east-1.amazonaws.com/images/qrCode/folheto_qr_code-01.png')",
          backgroundPosition: 'center top',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%',
          padding: '535px 0px 0px 330px',
        }}
      >
        <div
          style={{
            backgroundColor: '#fff',
            width: 400,
            height: 400,
            borderRadius: '50px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <QRCode
            value={this.props.qrCode}
            size={300}
            bgColor="#ffffff"
            fgColor="#303030"
            level="L"
            includeMargin={false}
            renderAs="svg"
          />
          <Title level={3} margin="10px 0px 0px 0px">
            {this.props.name}
          </Title>
        </div>
      </div>
    );
  }
}

class Example extends React.Component {
  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => (
            <Button type="primary" style={{ marginBottom: 10 }}>
              Imprimir Display do Meu Negócio
            </Button>
          )}
          content={() => this.componentRef}
        />
        <ComponentToPrint
          qrCode={this.props.qrCode}
          name={this.props.name}
          ref={(el) => (this.componentRef = el)}
        />
      </div>
    );
  }
}

export default Example;
