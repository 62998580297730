import React from 'react';
import Chart from 'react-apexcharts';

const options = {
  chart: {
    id: 'basic-bar',
  },
  xaxis: {
    categories: [1, 2, 3, 4, 5],
  },
};
const series = [
  {
    name: 'Quantidade de beneficiários simultaneamente em programas',
    data: [30, 40, 50, 22, 6],
  },
];

const Graph1 = () => {
  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      height="300"
      width="80%"
    />
  );
};

export default Graph1;
