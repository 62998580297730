import React, { useEffect, useContext, useState } from 'react';
// Contexts
import { AuthContext } from '~/contexts/AuthContext';
import { AxiosContext } from '~/contexts/AxiosContext';
//Libraries
import { Divider } from 'antd';
//Own Components
import Cards from './components/Cards';
import PrintContent from './components/PrintContent';
//Utils
import { UserName } from '~/utils/UserInformation';

const isDebug = false;

export default function Home() {
  const { user } = useContext(AuthContext);
  const { CallToTheAPI } = useContext(AxiosContext);
  const [qrCodeCC, setQRCodeCC] = useState();

  useEffect(() => {
    async function GetQRCode() {
      if (isDebug) console.log('🚀 API: Get QR Code ');
      const params = {
        operation: ['Partner', 'GetQRCode'],
      };
      const response = await CallToTheAPI(params);
      if (isDebug) console.log('  ⚡ API Response (Get QR Code): ', response);
      if (response.success) {
        if (response.body.newQRCode) {
          await setQRCodeCC(response.body.newQRCode._id);
        } else {
          await setQRCodeCC(response.body.QRCode._id);
        }
      }
    }
    if (user) {
      GetQRCode();
    }
  }, [CallToTheAPI, user]);

  return (
    <div>
      <Cards />
      <Divider />
      <PrintContent qrCode={qrCodeCC || '123'} name={UserName()} />
    </div>
  );
}
