import React, { useContext, useState, useEffect } from 'react';
// Libraries
import { DeleteOutlined, QrcodeOutlined } from '@ant-design/icons';
import { message, Popconfirm, Modal } from 'antd';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Components
import Table from '~/components/table';
import Button from '~/components/button';
import Text from '~/components/typography/text';
import { ImageWithPrimaryAndSecondaryTextCell } from '~/components/table/cells';
//Own Components
import PrintContent from './components/PrintContent';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Content({ places, activity }) {
  const { theme } = useContext(ThemeContext);

  const [visible, setVisible] = useState(false);
  const [qrCode, setQRCode] = useState();
  const [placeInformation, setPlaceInformation] = useState();
  const [activityInformation, setActivityInformation] = useState();
  const [loadingTable, setLoadingTable] = useState(true);

  const tableColumns = [
    {
      title: 'Local',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <ImageWithPrimaryAndSecondaryTextCell
          image={record.avatar}
          primaryText={name}
          secondaryText={RenderAddressInformation(record)}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (action, record) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            type="primary"
            icon={<QrcodeOutlined />}
            style={{ marginRight: 10 }}
            onClick={() => GetActivityQRCode(record)}
          >
            Gerar QR Code
          </Button>
          <Popconfirm
            placement="top"
            title="Deseja desvincular esse local?"
            onConfirm={() => UnlinkLocation(record.id)}
            okText="Sim, desvincular agora"
            cancelText="Não"
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 5,
                border: `1px solid ${theme.color.borderColor}`,
                borderRadius: 10,
                cursor: 'pointer',
              }}
            >
              <DeleteOutlined
                style={{ color: theme.color.danger, marginRight: 10 }}
              />
              <Text>Desvincular local</Text>
            </div>
          </Popconfirm>
        </div>
      ),
    },
  ];

  useEffect(() => {
    async function GetActivityInformation() {
      const params = {
        operation: ['Partner', 'Activity', 'Show'],
        urlParams: {
          activityId: activity.id,
        },
      };
      const response = await SocialMeAPI(params);
      console.log(response);
      if (response.success) {
        setActivityInformation(response.body.activity);
        return setLoadingTable(false);
      }
    }
    if (activity) {
      GetActivityInformation();
    }
  }, [activity]);

  async function GetActivityQRCode(place) {
    console.log(place);
    const params = {
      operation: ['Partner', 'Activity', 'Location', 'CreateQRCode'],
      data: {
        activity: activity.id,
        place: place.id,
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      setPlaceInformation(place);
      setQRCode(response.body.QRCode._id);
      return ShowModal();
    }
    return message.error('Alguma coisa aconteceu...');
  }

  async function UnlinkLocation(placeId) {
    const params = {
      operation: ['Partner', 'Activity', 'Location', 'Delete'],
      urlParams: {
        activityId: activity.id,
        placeId: placeId,
      },
    };
    const response = await SocialMeAPI(params);
    console.log(response);
    return message.info('Operação inacessível no momento');
  }

  function ShowModal() {
    setVisible(true);
  }
  function CloseModal() {
    setVisible(false);
  }

  const RenderAddressInformation = (record) => {
    return `${record.address_designation || ''} ${
      record.address_address ? record.address_address + ',' : ''
    } ${record.address_number ? record.address_number + ',' : ''} ${
      record.address_neighborhood ? record.address_neighborhood + '.' : ''
    } ${record.address_city_name ? record.address_city_name + ' -' : ''}
     ${record.address_state_name ? record.address_state_name + ' -' : ''} ${
      record.address_country_name ? record.address_country_name : ''
    }`;
  };

  return (
    <div>
      <Table
        dataReceived={places}
        tableColumns={tableColumns}
        loading={loadingTable}
      />
      <Modal
        title="Imprimir QR Code da Atividade"
        centered
        visible={visible}
        onOk={CloseModal}
        onCancel={CloseModal}
        footer={null}
        width={'auto'}
      >
        <PrintContent
          qrCode={qrCode || '123'}
          place={placeInformation}
          activityInformation={activityInformation}
        />
      </Modal>
    </div>
  );
}
